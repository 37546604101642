/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Валюта
 */
export type CurrencyTypeResponse = {
    /**
     * Валюта
     */
    currencyType?: CurrencyTypeResponse.currencyType;
    /**
     * Валюта
     */
    currencyTypeDescription?: string;
    /**
     * Минимальная сумма заказа для данной валюты
     */
    minimalSumOrder?: number;
};

export namespace CurrencyTypeResponse {

    /**
     * Валюта
     */
    export enum currencyType {
        RUB = 'RUB',
        UZS = 'UZS',
        USD = 'USD',
    }


}

