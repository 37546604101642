import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';

const noop = () => {};

@Component({
	selector: 'tk-radio',
	templateUrl: './radio.component.html',
	styleUrls: ['./radio.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioComponent), multi: true }],
})
export class RadioComponent implements ControlValueAccessor {
	onChangeCallback: (v: any) => void = noop;
	onTouchedCallback = noop;
	value: any;
	valueChanges = new Subject();

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	writeValue(value: any): void {
		this.value = value;
	}

	setValue(value: any) {
		this.value = value;
		this.valueChanges.next(value);
		this.onChangeCallback(value);
	}
}
