<ng-container *transloco="let t">
	<div class="wrap">
		<tk-form-field>
			<input type="text" tkInput [formControl]="searchControl" [placeholder]="t('order.city_search')" />

			<tk-icon icon="search" size="14"></tk-icon>
		</tk-form-field>
		<ng-container *ngFor="let point of list | async">
			<div
				class="point"
				(click)="setCity(point)"
				[class.point--active]="cartService.cityForm.controls.selectedCity.value === point.address.city"
			>
				<div class="point__between">
					<div>
						<div class="point__name">{{ point.address }}</div>
					</div>
					<tk-icon icon="check" size="24"></tk-icon>
				</div>
				<span class="point__separator"></span>
			</div>
		</ng-container>
	</div>
</ng-container>
