<ng-container *transloco="let t">
	<ng-container *ngIf="loading$ | async">
		<div class="skeleton">
			<tk-skeleton class="skeleton__photo"></tk-skeleton>
			<tk-skeleton class="skeleton__title"></tk-skeleton>
			<div class="skeleton__line"></div>
			<tk-skeleton class="skeleton__subtitle"></tk-skeleton>
			<tk-skeleton class="skeleton__text"></tk-skeleton>
			<div class="skeleton__line"></div>
		</div>
	</ng-container>

	<ng-container *ngIf="product">
		<tk-product-photo [photos]="photos"></tk-product-photo>

		<h1 class="title">{{ product.productName }}</h1>

		<p *ngIf="product.shortDescription">{{ product.shortDescription }}</p>

		<div class="characteristics">
			<div *ngIf="hasColorPicker" class="characteristic characteristic--row">
				<span class="characteristic__label">{{ t('catalog.color') }}</span>
				<div class="characteristic__value">
					<tk-color-picker
						[value]="activeColor"
						[colors]="getColorsWithAmount()"
						(valueChange)="onSelectColor($event)"
						[withShadow]="true"
					></tk-color-picker>
				</div>
			</div>
			<div
				*ngIf="
					positions.length > 1 || (positions.length === 1 && positions[0].size?.sizeValue !== 'Без размера')
				"
				class="characteristic characteristic--row"
			>
				<span class="characteristic__label">{{ t('catalog.size') }}</span>
				<div class="characteristic__value">
					<tk-size-picker [(value)]="activePosition" [sizes]="positions"></tk-size-picker>
				</div>
			</div>
			<div class="characteristic">
				<h4 class="characteristic__title">{{ t('catalog.details') }}</h4>
				<p
					class="characteristic__description"
					[innerHTML]="
						!isReadMore ? product.fullDescription : product.fullDescription?.substring(0, textLimit)
					"
					id="desc"
					[ngClass]="{ 'characteristic__description--with-limit': isLimit }"
				></p>
				<span>{{ isLimit && isReadMore ? '...' : '' }}</span>
				<button *ngIf="isLimit" class="read-more" type="button" (click)="isReadMore = !isReadMore">
					{{ isReadMore ? t('catalog.more') : t('catalog.hide') }}
				</button>
			</div>
		</div>
	</ng-container>
	<tk-author class="footer" [class.footer--show]="showFooter"></tk-author>
	<div class="price__container" *tkLet="activeVendor$ | async as activeVendor">
		<div class="price">
			{{
				product?.digitalGoods?.realPrice
					? product?.digitalGoods?.realPrice?.toLocaleString()
					: activePosition?.realPrice?.toLocaleString()
			}}
			{{ shopService.shopMoneySign.value }}

			<div class="price__discount" *ngIf="activePosition?.discount">
				{{ activePosition?.price!.toLocaleString() }} {{ shopService.shopMoneySign.value }}
			</div>

			<div class="price__discount" *ngIf="product?.digitalGoods?.discount">
				{{ product?.digitalGoods?.price!.toLocaleString() }} {{ shopService.shopMoneySign.value }}
			</div>
		</div>

		<tk-counter
			class="product__counter"
			[count]="(cart$ | async)?.items | productCartCount: (product?.digitalGoods! ? product : activePosition)"
			[color]="activeColor"
			[currentAmount]="
				product?.digitalGoods?.amount ? product!.digitalGoods!.amount : activeVendor$.value?.amountPositions
			"
			(decrement)="removeFromCart()"
			(increment)="addToCart()"
		></tk-counter>
	</div>
	<ng-container *ngIf="(cartService.productsInCart$ | async)?.items?.length">
		<tk-main-button [text]="t('order.cart')"  [click]='goToCart'></tk-main-button
	></ng-container>
</ng-container>
