/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddressRequest } from './AddressRequest';
import type { ApishipDeliveryRequest } from './ApishipDeliveryRequest';
import type { ReceiverRequest } from './ReceiverRequest';

/**
 * Форма оформления заказа
 */
export type OrderRequest = {
    /**
     * Вариант доставки
     */
    deliveryOption: OrderRequest.deliveryOption;
    /**
     * Пункт выдачи
     */
    pickUpPointId?: string;
    address?: AddressRequest;
    receiver: ReceiverRequest;
    apishipDelivery?: ApishipDeliveryRequest;
    /**
     * Комментарий к заказу
     */
    comment?: string;
    /**
     * Промокод
     */
    promocodeId?: string;
    /**
     * Тип оплаты
     */
    paymentType?: OrderRequest.paymentType;
    /**
     * Выбранный кастомный тип оплаты
     */
    customPaymentTypeId?: string;
};

export namespace OrderRequest {

    /**
     * Вариант доставки
     */
    export enum deliveryOption {
        PICK_UP = 'PICK_UP',
        DELIVERY = 'DELIVERY',
        APISHIP = 'APISHIP',
        NONE = 'NONE',
    }

    /**
     * Тип оплаты
     */
    export enum paymentType {
        NONE = 'NONE',
        TELEGRAM = 'TELEGRAM',
        TINKOFF = 'TINKOFF',
        CUSTOM = 'CUSTOM',
    }


}

