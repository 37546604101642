import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { CartService } from '@services/cart.service';
import { OrderCreatedResponse, ProductItemResponse } from '@models/generated/api';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyService } from '@services/destroy.service';
import { MainButtonService } from '@services/main-button.service';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { ShopService } from '@services/shop.service';
import { currencyLangBinding } from '@pages/shop/shop.component';
import { AuthService } from '@services/auth.service';
import { InvoiceStatus } from '@models/telegram/invoice-status';

@Component({
	selector: 'tk-cart',
	templateUrl: './order-info.component.html',
	styleUrls: ['./order-info.component.sass'],
	providers: [DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderInfoComponent {
	shopId;
	orderId;
	orderData = new BehaviorSubject<any>({});

	constructor(
		public cartService: CartService,
		private route: ActivatedRoute,
		private router: Router,
		private mainButton: MainButtonService,
		private destroy$: DestroyService,
		private cdr: ChangeDetectorRef,
		private translocoService: TranslocoService,
		public shopService: ShopService,
		private location: Location,
		private zone: NgZone,
		private authService: AuthService,
	) {
		this.shopId = this.route.snapshot.params['shopId'];
		this.orderId = this.route.snapshot.params['orderId'];

		if (!this.authService.token) {
			this.authService.signIn(this.shopId, { webappInitData: window.Telegram.WebApp.initData }).subscribe({
				next: response => {
					this.authService.authorize(response, window.Telegram.WebApp.initData);
					this.shopService.getShopInfo(this.shopId).subscribe({
						next: res => {
							this.shopService.subscriptionFeatures.next(res.subscriptionFeatures!);

							this.translocoService.setActiveLang(
								currencyLangBinding[res.currencyType!.currencyType || 'RUB'],
							);
							this.shopService.setMoneySign(res.currencyType!);

							this.cartService.isWithoutPayment = res.paymentInfo!.withoutPayment!;
							this.cartService.paymentAvailable = !!res?.paymentInfo!.paymentTypes?.length && !res?.paymentInfo!.paymentTypes?.find(i => i === "NONE");

							this.shopService.shopProductType.next(res.productType!.productType!);
						},
					});
					this.getOrderData();
				},
				error: () => {
					window.Telegram.WebApp.showAlert('Oшибка');
				},
			});
		} else {
			this.getOrderData();
		}
	}

	getOrderData() {
		this.shopService.getOrderInfo(this.shopId, this.orderId).subscribe({
			next: (res: any) => {
				if (res.paymentState === 'NOT_PAID') {
					window.Telegram.WebApp.MainButton.show();
				} else {
					window.Telegram.WebApp.MainButton.hide();
					window.Telegram.WebApp.close();
				}
				this.orderData.next(res);
				this.cdr.detectChanges();
			},
			error: () => {
				window.Telegram.WebApp.showAlert('Произошла ошибка');
			},
		});
	}

	sendNotify(shopId: string, orderId: string) {
		this.cartService.sendNotify(shopId, orderId).subscribe({
			next: () => {},
		});
	}
	goToPayment = () => {
		if (this.orderData.value.paymentType === OrderCreatedResponse.paymentType.TELEGRAM) {
			window.Telegram.WebApp.openInvoice(this.orderData.value.paymentLink!, (status: InvoiceStatus) => {
				window.Telegram.WebApp.close();
			});
		} else {
			window.Telegram.WebApp.MainButton.hide();
			this.zone.run(() => {
				window.Telegram.WebApp.openLink(this.orderData.value.paymentLink!);
			});
			window.Telegram.WebApp.close();
		}
	};
}
