/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Форма редактирования токена оплаты для магазина
 */
export type PaymentTbankDtoRequest = {
    /**
     * Ключ мерчанта тинькофф
     */
    merchantKey: string;
    /**
     * Пароль мерчанта тинькофф
     */
    password: string;
    /**
     * Версия ФФД
     */
    ffdVersion: PaymentTbankDtoRequest.ffdVersion;
    /**
     * Тип налогообложения
     */
    taxation: PaymentTbankDtoRequest.taxation;
    /**
     * НДС
     */
    vatCode: PaymentTbankDtoRequest.vatCode;
};

export namespace PaymentTbankDtoRequest {

    /**
     * Версия ФФД
     */
    export enum ffdVersion {
        FFD_105 = 'FFD_105',
        FFD_2 = 'FFD_2',
    }

    /**
     * Тип налогообложения
     */
    export enum taxation {
        OSN = 'OSN',
        USN_INCOME = 'USN_INCOME',
        USN_INCOME_OUTCOME = 'USN_INCOME_OUTCOME',
        ENVD = 'ENVD',
        ESN = 'ESN',
        PATENT = 'PATENT',
    }

    /**
     * НДС
     */
    export enum vatCode {
        NO_VAT = 'NO_VAT',
        VAT_0 = 'VAT_0',
        VAT_10 = 'VAT_10',
        VAT_20 = 'VAT_20',
        VAT_10_110 = 'VAT_10_110',
        VAT_20_120 = 'VAT_20_120',
    }


}

