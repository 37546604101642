import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ShopComponent } from '@pages/shop/shop.component';
import { ProductsComponent } from '@pages/products/products.component';
import { ProductComponent } from '@pages/product/product.component';
import { CartComponent } from '@pages/cart/cart.component';
import { DeliveryComponent } from '@pages/delivery/delivery.component';
import { CheckoutComponent } from '@pages/checkout/checkout.component';
import { OrderSuccessComponent } from '@pages/order/order-success/order-success.component';
import { CategoriesComponent } from '@pages/products/categories/categories.component';
import { PickupPointsComponent } from '@pages/delivery/pickup-points/pickup-points.component';
import { SortComponent } from '@pages/products/sort/sort.component';
import { OrderFallbackComponent } from '@pages/order/order-fallback/order-fallback.component';
import { OrderInfoComponent } from '@pages/order/order-info/order-info.component';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: 'shops/:shopId',
				component: ShopComponent,
				children: [
					{
						path: 'products',
						component: ProductsComponent,
					},
					{
						path: 'categories',
						component: CategoriesComponent,
					},
					{
						path: 'sort',
						component: SortComponent,
					},
					{
						path: 'products/:productId',
						component: ProductComponent,
					},
					{
						path: 'cart',
						component: CartComponent,
					},
					{
						path: 'checkout',
						component: CheckoutComponent,
					},
					{
						path: 'delivery',
						component: DeliveryComponent,
					},
					{
						path: 'pickup-points',
						component: PickupPointsComponent,
					},
				],
			},
			{
				path: 'shops/:shopId/order-success',
				component: OrderSuccessComponent,
			},
			{
				path: 'shops/:shopId/order-fallback',
				component: OrderFallbackComponent,
			},
			{
				path: 'shops/:shopId/order/:orderId',
				component: OrderInfoComponent,
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
