import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SwiperModule } from 'swiper/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ShopComponent } from '@pages/shop/shop.component';
import { ProductsComponent } from '@pages/products/products.component';
import { ProductCardComponent } from '@pages/products/product-card/product-card.component';
import { ProductComponent } from '@pages/product/product.component';
import { ProductPhotoComponent } from '@pages/product/product-photo/product-photo.component';
import { ButtonComponent } from '@components/ui/button/button.component';
import { ColorPickerComponent } from '@components/forms/color-picker/color-picker.component';
import { CounterComponent } from '@components/ui/counter/counter.component';
import { IconComponent } from '@components/ui/icon/icon.component';
import { SizePickerComponent } from '@components/forms/size-picker/size-picker.component';
import { FormatCurrencyPipe } from '@pipes/format-currency.pipe';
import { GetFilePipe } from '@pipes/get-file.pipe';
import { UrlInterceptorService } from '@services/url-interceptor';
import { AuthInterceptorService } from '@services/auth-interceptor.service';
import { LocationBackDirective } from '@directives/location-back.directive';
import { AuthorComponent } from '@components/ui/author/author.component';
import { LetDirective } from '@directives/let.directive';
import { CartComponent } from '@pages/cart/cart.component';
import { ProductCartCountPipe } from '@pipes/product-cart-count.pipe';
import { FormFieldComponent } from '@components/forms/form-field/form-field.component';
import { DeliveryComponent } from '@pages/delivery/delivery.component';
import { MainButtonDirective } from '@components/ui/main-button.directive';
import { CheckoutComponent } from '@pages/checkout/checkout.component';
import { FormatDeliveryTypePipe } from '@pipes/format-delivery-type.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { TabPickerComponent } from '@components/forms/tab-picker/tab-picker.component';
import { RadioComponent } from '@components/forms/radio/radio.component';
import { RadioOptionComponent } from '@components/forms/radio/radio-option/radio-option.component';
import { FormatAddressPipe } from '@pipes/format-address.pipe';
import { FormatReceiverPipe } from '@pipes/format-receiver.pipe';
import { BackButtonDirective } from '@components/ui/back-button.directive';
import { NgxMaskModule } from 'ngx-mask';
import { InputTrimDirective } from '@directives/input-trim.directive';
import { OrderSuccessComponent } from '@pages/order/order-success/order-success.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SkeletonComponent } from '@components/ui/skeleton/skeleton.component';
import { CategoriesComponent } from '@pages/products/categories/categories.component';
import { TkAutocompleteModule } from '@technokratos/components-angular/autocomplete';
import { OverlayModule } from '@angular/cdk/overlay';
import { PickupPointsComponent } from '@pages/delivery/pickup-points/pickup-points.component';
import { SearchPipe } from '@pipes/search.pipe';
import { TranslocoRootModule } from './transloco-root.module';
import { AngularYandexMapsModule, YaConfig } from 'angular8-yandex-maps';
import { SortComponent } from '@pages/products/sort/sort.component';
import { OrderFallbackComponent } from '@pages/order/order-fallback/order-fallback.component';
import { OrderInfoComponent } from '@pages/order/order-info/order-info.component';
import { Zoom } from 'swiper';
import { ZoomModalComponent } from '@app/modals/zoom-modal/zoom-modal.component';
import { DialogModule } from '@angular/cdk/dialog';

const mapConfig: YaConfig = {
	apikey: '2446b987-df7d-4664-b556-b4e57ef18cc2',
	lang: 'ru_RU',
};

@NgModule({
	declarations: [
		AppComponent,
		ShopComponent,
		ProductsComponent,
		ProductCardComponent,
		ProductComponent,
		ButtonComponent,
		ColorPickerComponent,
		CounterComponent,
		IconComponent,
		SizePickerComponent,
		FormatCurrencyPipe,
		GetFilePipe,
		LocationBackDirective,
		ProductPhotoComponent,
		AuthorComponent,
		LetDirective,
		CartComponent,
		ProductCartCountPipe,
		FormFieldComponent,
		DeliveryComponent,
		MainButtonDirective,
		CheckoutComponent,
		FormatDeliveryTypePipe,
		TabPickerComponent,
		RadioComponent,
		RadioOptionComponent,
		FormatAddressPipe,
		FormatReceiverPipe,
		BackButtonDirective,
		OrderSuccessComponent,
		OrderFallbackComponent,
		SkeletonComponent,
		CategoriesComponent,
		SortComponent,
		PickupPointsComponent,
		SearchPipe,
		OrderInfoComponent,
		ZoomModalComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		SwiperModule,
		ReactiveFormsModule,
		InputTrimDirective,
		NgxMaskModule.forRoot(),
		InfiniteScrollModule,
		TkAutocompleteModule,
		OverlayModule,
		TranslocoRootModule,
		AngularYandexMapsModule.forRoot(mapConfig),
		DialogModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UrlInterceptorService,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			multi: true,
			useClass: AuthInterceptorService,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
