<ng-container *transloco="let t">
	<div class="product" (click)="navigateToProduct()">
		<ng-container *ngIf="product.photo">
			<img [src]="(product.photo?.file?.id | getFile: 640 | async) || '/assets/default-img.jpg'" alt height="225" class="product__photo" />
		</ng-container>
		<ng-container *ngIf="!product.photo">
			<img src="/assets/default-img.jpg" alt height="225" class="product__photo" />
		</ng-container>
		<div class="product__content">
			<div class="product__prices">
				<span class="product__price"
					>{{ product.realPrice!.toLocaleString() }} {{ shopService.shopMoneySign.value }}</span
				>
				<span *ngIf="product.price && product.discount" class="product__full-price"
					>{{ product.price.toLocaleString() }} {{ shopService.shopMoneySign.value }}</span
				>
			</div>
			<p class="product__name">{{ product.productName }}</p>
			<div *ngIf="hasColorPicker" class="product__color">
				<p class="product__color-label">{{ t('catalog.color') }}</p>
				<tk-color-picker
					[(value)]="activeColor"
					[colors]="product.colors"
					[isDisabled]="true"
				></tk-color-picker>
			</div>
		</div>
		<a tkButton [routerLink]="['./', product.productId]">
			<tk-icon icon="plus" [size]="12"></tk-icon>
			{{ t('catalog.add_to_card') }}
		</a>
	</div>
</ng-container>
