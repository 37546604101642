import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { ProductsService } from '@services/products.service';
import {
	ColorResponse,
	PositionResponse,
	ProductResponse,
	VendorCodePhotoResponse,
	VendorCodeResponse,
} from '@models/generated/api';
import { CartService } from '@services/cart.service';
import { DestroyService } from '@services/destroy.service';
import { AuthService } from '@services/auth.service';
import { LoadingService } from '@services/loading.service';
import { ShopService } from '@services/shop.service';
import { TranslocoService } from '@ngneat/transloco';
import { currencyLangBinding } from '@pages/shop/shop.component';
import { Location } from '@angular/common';

@Component({
	selector: 'tk-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
})
export class ProductComponent {
	product?: ProductResponse;

	activeVendor$ = new BehaviorSubject<VendorCodeResponse | null>(null);

	activeColor?: ColorResponse;
	activePosition?: PositionResponse | null | any;

	colors: Array<ColorResponse> = [];
	positions: Array<PositionResponse> = [];
	photos: Array<VendorCodePhotoResponse> = [];

	cart$ = this.cartService.productsInCart$;

	isLimit: boolean = false;
	isReadMore: boolean = true;
	textLimit: number = 200;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private productsService: ProductsService,
		private cdr: ChangeDetectorRef,
		public cartService: CartService,
		private destroy$: DestroyService,
		private authService: AuthService,
		public loading$: LoadingService,
		private translocoService: TranslocoService,
		public shopService: ShopService,
		private location: Location,
	) {
		this.loading$.next(true);
		if (!this.authService.token) {
			this.authService.signIn(this.shopId, { webappInitData: window.Telegram.WebApp.initData }).subscribe({
				next: response => {
					this.authService.authorize(response, window.Telegram.WebApp.initData);
					this.shopService.getShopInfo(this.shopId).subscribe({
						next: res => {
							this.shopService.subscriptionFeatures.next(res.subscriptionFeatures!);

							this.translocoService.setActiveLang(
								currencyLangBinding[res.currencyType!.currencyType || 'RUB'],
							);
							this.shopService.setMoneySign(res.currencyType!);

							this.cartService.isWithoutPayment = res.paymentInfo!.withoutPayment!;
							this.cartService.paymentAvailable = !!res?.paymentInfo!.paymentTypes?.length && !res?.paymentInfo!.paymentTypes?.find(i => i === "NONE");

							this.shopService.shopProductType.next(res.productType!.productType!);
						},
					});
					this.getProduct();
					this.cartService.refreshCart(this.shopId);
				},
				error: () => {
					if (this.authService.webappInitData) {
						this.authService
							.signIn(this.shopId, { webappInitData: this.authService.webappInitData })
							.subscribe({
								next: response => {
									this.authService.authorize(response, this.authService.webappInitData!);
									this.shopService.getShopInfo(this.shopId).subscribe({
										next: res => {
											this.shopService.subscriptionFeatures.next(res.subscriptionFeatures!);

											this.translocoService.setActiveLang(
												currencyLangBinding[res.currencyType!.currencyType || 'RUB'],
											);
											this.shopService.setMoneySign(res.currencyType!);

											this.cartService.isWithoutPayment = res.paymentInfo!.withoutPayment!;
											this.cartService.paymentAvailable =
												!!res?.paymentInfo!.paymentTypes?.length && !res?.paymentInfo!.paymentTypes?.find(i => i === "NONE");

											this.shopService.shopProductType.next(res.productType!.productType!);
										},
									});
									this.getProduct();
									this.cartService.refreshCart(this.shopId);
								},
							});
					} else {
						this.router.navigate([`shops`, this.shopId]);
					}
				},
			});
		} else {
			this.getProduct();
			this.cartService.refreshCart(this.shopId);
		}

		this.activeVendor$.pipe(takeUntil(this.destroy$)).subscribe(vendor => {
			if (vendor) {
				this.activeColor = vendor.color;
				this.photos = this.product!.vendorCodes?.find(
					vendorCode => vendorCode.color!.id === this.activeColor!.id,
				)!.photos as VendorCodePhotoResponse[];

				if (this.product?.digitalGoods) {
					this.positions = [];
					this.activePosition = this.product;
				} else {
					this.activePosition = vendor.positions?.find(pos => pos.amount && pos.amount > 0) ?? null;
					this.positions = vendor.positions as PositionResponse[];
				}
			}
			if (this.product?.digitalGoods) {
				this.photos = this.product?.digitalGoods.photos!;
			}
		});

		const BackButton = window.Telegram.WebApp.BackButton;
		BackButton.show();
	}

	get shopId() {
		return this.route.parent?.snapshot.params['shopId'];
	}

	getProduct() {
		this.loading$.next(true);
		const { productId } = this.route.snapshot.params;
		this.productsService
			.getProduct(this.shopId, productId)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: response => {
					this.product = response;

					this.isLimit = (response.fullDescription ?? '').length > this.textLimit;

					if (response?.vendorCodes?.length) {
						this.activeVendor$.next(response.vendorCodes.find(vendor => vendor.amountPositions! > 0)!);
						this.colors = response.vendorCodes?.map(
							(vendorCode: any) => vendorCode.color,
						) as ColorResponse[];
					}
					if (response.digitalGoods) {
						this.activeVendor$.next(response?.digitalGoods);
					}
					this.loading$.next(false);
					this.cdr.markForCheck();
				},
			});
	}

	getColorsWithAmount() {
		return this.colors.map(color => {
			return {
				...color,
				amountPositions: this.product?.vendorCodes?.find(item => item.color?.id === color.id)?.amountPositions,
			};
		});
	}

	onSelectColor(color: ColorResponse): void {
		if (!this.product) {
			return;
		}
		const vendor = this.product.vendorCodes!.find(vendorCode => vendorCode.color!.id === color.id) ?? null;
		this.activeVendor$.next(vendor);
	}

	addToCart() {
		if (!this.product) {
			return;
		}
		this.cartService.changeAmountCart(1, this.shopId, this.activePosition?.id, !!this.activePosition?.digitalGoods);
	}

	removeFromCart() {
		if (!this.product || (!this.activeColor && !this.product.digitalGoods)) {
			return;
		}

		this.cartService.changeAmountCart(
			-1,
			this.shopId,
			this.activePosition?.id,
			!!this.activePosition?.digitalGoods,
		);
	}

	get hasColorPicker(): boolean {
		return Boolean(
			(this.colors && this.colors?.length > 1) || (this.colors?.length === 1 && this.colors[0].id !== 'DEFAULT'),
		);
	}

	get showFooter(): boolean {
		return (
			!this.shopService.subscriptionFeatures.value?.find(feature => feature.code === 'NO_ADS')?.available! ?? true
		);
	}

	goToCart = () => {
		this.router.navigate([`shops`, this.shopId, 'cart']);
	};
}
