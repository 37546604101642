/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Информация об импорте товара
 */
export type ProductImportShortResponse = {
    /**
     * Идентификатор процедуры импорта
     */
    id?: string;
    /**
     * Имя файла
     */
    fileName?: string;
    /**
     * Статус импорта
     */
    jobState?: ProductImportShortResponse.jobState;
    /**
     * Статус импорта
     */
    jobStateDescription?: string;
    /**
     * Импорт в процессе
     */
    isRunning?: boolean;
    /**
     * Дата импорта
     */
    importDate?: string;
    /**
     * Количество успешно импортированных товаров
     */
    successCount?: number;
    /**
     * Общее количество товаров
     */
    totalCount?: number;
};

export namespace ProductImportShortResponse {

    /**
     * Статус импорта
     */
    export enum jobState {
        CREATED = 'CREATED',
        STARTED = 'STARTED',
        READ_CATEGORY = 'READ_CATEGORY',
        OFFER_IMPORT = 'OFFER_IMPORT',
        FINISHED_SUCCESS = 'FINISHED_SUCCESS',
        FINISHED_WARN = 'FINISHED_WARN',
        FINISHED_ERROR = 'FINISHED_ERROR',
        FINISHED_ALL_ERROR = 'FINISHED_ALL_ERROR',
        FAILED = 'FAILED',
    }


}

