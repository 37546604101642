import {
	AbstractControl,
	FormControl,
	FormGroup,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';

export const EmailRegExp = /^\S+@\S+\.\S+$/;
export const NumbersRegExp = /\d/;

export const EmailValidators = [Validators.email, Validators.pattern(EmailRegExp), Validators.maxLength(254)];

export const PasswordSpecialSymbols = /^[a-z\d!@#$%^&*()_+=;:,.\/?|`~ \[\]{}]+$/i;

const getField = (form: FormGroup, field: string): FormGroup | undefined => {
	const [first, ...rest] = field.split('.');
	if (!rest.length) {
		return form.get(first) as FormGroup;
	}
	return getField(form.get(first) as FormGroup, rest.join('.'));
};

export const errors = (form: UntypedFormGroup, field: string, onInit = false): ValidationErrors | null | undefined => {
	const control = getField(form, field);
	const showOnInit = onInit ? true : control?.touched || control?.dirty;

	return showOnInit ? control?.errors : null;
};

export const CheckIfErrorEmpty = (error: ValidationErrors | null) => {
	return error && Object.keys(error).length ? error : null;
};

export const validatePasswords = (
	passField: string = 'password',
	confirmPasswordField: string = 'passwordRepeat',
	emailField: string = 'email',
): ValidatorFn => {
	return (control: AbstractControl): ValidationErrors | null => {
		const email: AbstractControl | null = control?.get(emailField);
		const password: AbstractControl | null = control?.get(passField);
		const confirmPassword: AbstractControl | null = control?.get(confirmPasswordField);

		const passwordErrors = password?.errors ?? null;
		const confirmPasswordErrors = confirmPassword?.errors ?? null;

		if (email?.value && password?.value && email?.value === password?.value) {
			password?.setErrors({ ...passwordErrors, duplicateEmail: true }, { emitEvent: false });
			return { duplicateEmail: true };
		}

		if (password?.value !== confirmPassword?.value) {
			confirmPassword?.setErrors({ ...confirmPasswordErrors, notSame: true }, { emitEvent: false });
			return { notSame: true };
		}

		delete passwordErrors?.['duplicateEmail'];
		delete confirmPasswordErrors?.['notSame'];
		password?.setErrors(CheckIfErrorEmpty(passwordErrors), { emitEvent: false });
		confirmPassword?.setErrors(CheckIfErrorEmpty(confirmPasswordErrors), { emitEvent: false });

		return null;
	};
};

export const validateStrongPassword: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
	const { value } = control;

	if (!value) {
		return null;
	}

	const hasNumber = /(\D*\d){2,}/.test(value);
	const hasUpper = /[A-Z]/.test(value);
	const hasLower = /[a-z]/.test(value);
	const hasRussianLetter = /[а-яё]/i.test(value);
	const hasSpace = / /.test(value);
	const hasWrongSymbols = PasswordSpecialSymbols.test(value);

	if (hasRussianLetter) return { hasRussianLetter: true };
	if (!hasNumber) return { withoutNumber: true };
	if (!hasUpper) return { withoutUpper: true };
	if (!hasLower) return { withoutLower: true };
	if (hasSpace) return { hasSpace: true };
	if (!hasWrongSymbols) return { invalidCharacters: true };

	return null;
};

export const PasswordValidators = [
	Validators.required,
	Validators.minLength(8),
	Validators.maxLength(32),
	validateStrongPassword,
];

export const noWhitespaceValidator = (control: FormControl) => {
	const isWhitespace = (control.value || '').trim().length === 0;
	const isValid = !isWhitespace;
	return isValid ? null : { 'required': true };
};
