import { ChangeDetectionStrategy, Component, Inject, Input, ViewChild } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ShopResponse, VendorCodePhotoResponse } from '@models/generated/api';
import { BehaviorSubject } from 'rxjs';
import { SwiperComponent } from 'swiper/angular';
import { LoadingService } from '@services/loading.service';
import { DestroyService } from '@services/destroy.service';

@Component({
	selector: 'tg-shop-create',
	templateUrl: './zoom-modal.component.html',
	styleUrls: ['./zoom-modal.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [LoadingService],
})
export class ZoomModalComponent {
	photos: Array<any> = [];

	@ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

	constructor(
		private dialogRef: DialogRef,
		@Inject(DIALOG_DATA) public dialogData: any,
		public loading$: LoadingService,
	) {
		this.loading$.next(true);


		this.photos = dialogData.photos;
		var imgs = document.images,
			len = imgs.length,
			counter = 0;

		const incrementCounter = () => {
			counter++;
			if ( counter === len ) {
				this.loading$.next(false);
			}
		}

		[].forEach.call( imgs, function( img: any ) {
			if(img.complete)
				incrementCounter();
			else
				img.addEventListener( 'load', incrementCounter, false );
		} );


	}

	close(): void {
		this.dialogRef.close();
	}
}
