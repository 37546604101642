import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Input } from '@angular/core';
import { RadioComponent } from '@components/forms/radio/radio.component';

@Component({
	selector: 'tk-radio-option',
	templateUrl: './radio-option.component.html',
	styleUrls: ['./radio-option.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioOptionComponent {
	@Input() value: any;

	constructor(private cdr: ChangeDetectorRef, private radio: RadioComponent) {
		this.radio.valueChanges.subscribe(() => this.cdr.markForCheck());
	}

	get selected(): boolean {
		return this.radio.value === this.value;
	}

	@HostListener('click') onClick() {
		this.radio.setValue(this.value);
	}
}
