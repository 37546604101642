import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ColorResponse } from '@models/generated/api';

export interface ColorWithAmount extends ColorResponse {
	amountPositions?: number;
}

@Component({
	selector: 'tk-color-picker',
	templateUrl: './color-picker.component.html',
	styleUrls: ['./color-picker-f.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorPickerComponent {
	@Input() colors?: Array<ColorWithAmount>;

	@Input() value?: ColorResponse;
	@Output() valueChange = new EventEmitter<ColorResponse>();
	@Input() isDisabled?: boolean = false;

	@Input() withShadow = false;

	@HostListener('click', ['$event'])
	onClick(event: Event) {
		event.stopPropagation();
	}
}
