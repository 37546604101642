import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DestroyService } from '@services/destroy.service';
import { LoadingService } from '@services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '@services/products.service';
import { CategoryResponse, OrderingResponse } from '@models/generated/api';

@Component({
	selector: 'tk-categories',
	templateUrl: './sort.component.html',
	styleUrls: ['./sort.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
})
export class SortComponent {
	sorting: any = this.productsService.sortings;
	activeSort: any = this.productsService.activeSort.value;
	shopId;

	constructor(private route: ActivatedRoute, public productsService: ProductsService, private router: Router) {
		this.shopId = this.route.parent?.snapshot.params['shopId'];

		if (!this.sorting.value) {
			this.toProducts();
		}

		const BackButton = window.Telegram.WebApp.BackButton;
		BackButton.show();
	}

	select(sort: OrderingResponse | null) {
		this.activeSort = sort;
	}

	choose = (): void => {
		this.productsService.activeSort.next(this.activeSort);
		this.productsService.newSort.next(true);
		this.toProducts();
	};

	toProducts() {
		this.router.navigate([`shops`, this.shopId, 'products']);
	}
}
