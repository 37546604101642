import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddressSuggestionResponse } from '@models/generated/api';

@Injectable({
	providedIn: 'root',
})
export class DadataService {
	constructor(private http: HttpClient) {}

	getDadata(query: string): Observable<AddressSuggestionResponse[]> {
		return this.http.get<AddressSuggestionResponse[]>('addresses', { params: { query } });
	}
}
