import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PositionResponse } from '@models/generated/api';

@Component({
	selector: 'tk-size-picker',
	templateUrl: './size-picker.component.html',
	styleUrls: ['./size-picker.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SizePickerComponent {
	@Input() sizes: Array<PositionResponse> = [];

	@Input() value?: PositionResponse | null;
	@Output() valueChange = new EventEmitter<PositionResponse>();
}
