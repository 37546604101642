import { Pipe, PipeTransform } from '@angular/core';
import { DeliveryType } from '@models/delivery-type.enum';

@Pipe({
	name: 'formatDeliveryType',
})
export class FormatDeliveryTypePipe implements PipeTransform {
	transform(value?: null | DeliveryType): string {
		switch (value) {
			case DeliveryType.PickUp:
				return 'Самовывоз';
			case DeliveryType.Delivery:
				return 'Доставка';
			default:
				return '';
		}
	}
}
