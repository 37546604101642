<ng-container *ngIf="photos.length > 0">
	<div class='swiper-wrap'>
<!--		<div-->
<!--			class='zoom'-->
<!--			(click)="openZoom()"-->
<!--			*ngIf='!isZoomOpened'-->
<!--		>-->
<!--			<tk-icon icon="zoom" size="16"></tk-icon>-->
<!--		</div>-->
		<swiper #swiper class="swiper"
		        [spaceBetween]="30"
		        [pagination]="true"
		>
			<ng-template *ngFor="let photo of photos" swiperSlide>
				<img (click)="openZoom()" [src]="photo.file!.id | getFile | async" alt height="450" class="swiper__photo" />
			</ng-template>
		</swiper>
	</div>


</ng-container>
<ng-container *ngIf="!(photos.length > 0)">
	<img class="default" src="/assets/default-img.jpg" alt="Нет фото" />
</ng-container>
