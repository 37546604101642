/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Форма создания нового магазина
 */
export type ShopCreateRequest = {
    /**
     * Токен для подключения к телеграм боту
     */
    token: string;
    /**
     * Название магазина
     */
    shopName: string;
    /**
     * Никнейм пользователя в телеграме для связи с ним
     */
    authorTelegramUserName?: string;
    /**
     * Минимальная сумма заказа
     */
    minimumOrderSum: number;
    /**
     * Валюта магазина
     */
    currency: ShopCreateRequest.currency;
    /**
     * Тип товаров в магазине
     */
    productType: ShopCreateRequest.productType;
};

export namespace ShopCreateRequest {

    /**
     * Валюта магазина
     */
    export enum currency {
        RUB = 'RUB',
        UZS = 'UZS',
        USD = 'USD',
    }

    /**
     * Тип товаров в магазине
     */
    export enum productType {
        GOODS = 'GOODS',
        DIGITAL = 'DIGITAL',
    }


}

