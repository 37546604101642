import { Injectable } from '@angular/core';
import { PageableInterface, WithPagingParams } from '@models/paging.interface';
import { ScrollIntoView } from '@utils/scrollIntoView';

export interface PaginatedResponse<T> {
	content?: Array<T>;
	pageable?: {
		sort: {
			empty: boolean;
			sorted: boolean;
			unsorted: boolean;
		};
		offset: number;
		pageNumber: number;
		pageSize: number;
		paged: boolean;
		unpaged: boolean;
	};
	totalPages?: number;
	totalElements?: number;
	last?: boolean;
	size?: number;
	number?: number;
	sort?: {
		empty: boolean;
		sorted: boolean;
		unsorted: boolean;
	};
	numberOfElements?: number;
	first?: boolean;
	empty?: boolean;
}

export const INIT_PAGE_SIZE = 10;
export const MAX_PAGES = 5;

export const EMPTY_PAGINATION: any = {
	size: INIT_PAGE_SIZE,
	page: 0,
};

@Injectable({
	providedIn: 'root',
})
export class PaginationModel<ModelType extends WithPagingParams = WithPagingParams> {
	currentSettings: ModelType = EMPTY_PAGINATION;

	pages(value: Partial<PageableInterface> | null, size: number = this.currentSettings.size ?? 0): number[] {
		if (!value) {
			return [];
		}

		const { totalPages, totalElements, number } = value;

		if (!totalPages || !totalElements || number === undefined) {
			return [];
		}

		const length = Math.ceil(totalElements / (size || INIT_PAGE_SIZE));

		const bigPages = length > MAX_PAGES;
		const bigPagesStart = number >= length - 2 ? length - MAX_PAGES : number > 1 ? number - 2 : 0;
		const startPage = bigPages ? bigPagesStart : 0;

		return new Array(bigPages ? MAX_PAGES : length).fill(null).map((value, index) => {
			return 1 + index + (bigPages ? startPage : 0);
		});
	}

	setAmount(amount: number): void {
		if (this.currentSettings.size === amount) {
			return;
		}

		this.currentSettings.size = amount;
		this.currentSettings.page = 0;
	}

	changePage(page: number): void {
		this.currentSettings = {
			...this.currentSettings,
			page,
		};

		ScrollIntoView('header-row');
	}
}
