import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'tk-skeleton, [tkSkeleton]',
	templateUrl: './skeleton.component.html',
	styleUrls: ['./skeleton.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'[class.tk-skeleton]': 'true',
	},
})
export class SkeletonComponent {}
