import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'tk-counter',
	templateUrl: './counter.component.html',
	styleUrls: ['./counter.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CounterComponent {
	@Input() count = 0;
	@Input() color?: any;
	@Input() currentAmount?: number;
	@Output() increment = new EventEmitter();
	@Output() decrement = new EventEmitter();

	onIncrement(event: Event): void {
		event.stopPropagation();
		this.increment.emit();
	}

	onDecrement(event: Event): void {
		event.stopPropagation();
		this.decrement.emit();
	}
}
