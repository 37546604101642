import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import {
	AddressSuggestionResponse,
	ApishipDeliveryRequest,
	ApishipDeliveryServicesWithMinimalCostDeliveryToDoor,
	ApishipPickUpPointsResponse,
	ApishipTariffResponse,
	BasketItemRequest,
	BasketResponse,
	BasketSettingsResponse,
	ColorResponse,
	DeliveryInfoResponse,
	OrderCreatedResponse,
	OrderRequest,
	PaymentInfoResponse,
	PickUpPointResponse,
	ProductForCatalogWebAppResponse,
} from '@models/generated/api';
import { ProductForOrderWebApp } from '@models/product-for-order-web-app';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DeliveryType } from '@models/delivery-type.enum';
import { NgFormsManager } from '@ngneat/forms-manager';
import { EmailValidators } from '@utils/validations';
import { ShopService } from '@services/shop.service';
import { TranslocoService } from '@ngneat/transloco';
import productDeliveryType = ApishipDeliveryRequest.productDeliveryType;

const findCallback =
	(product: ProductForCatalogWebAppResponse, color?: ColorResponse) =>
	(productInCart: ProductForOrderWebApp): boolean =>
		productInCart.productId === product.productId && productInCart?.color?.id === color?.id;

export interface OrderData extends Partial<Omit<OrderRequest, 'deliveryOption' | 'address'>> {
	deliveryOption: OrderRequest.deliveryOption;
	address?: any;
	deliveryTariff?: string | undefined;
	apishipPickupTariff?: string | undefined;
	fiasData?: object;
	promocodeId?: string;
}

@Injectable({
	providedIn: 'root',
})
export class CartService implements OnDestroy {
	productsInCart$ = new BehaviorSubject<BasketResponse>({
		realPrice: 0,
		items: [],
	});

	isWithoutPayment: boolean = false;
	paymentAvailable: boolean = true;
	promocodeId: string | null = null;

	destroy$ = new Subject();

	form = new FormGroup({
		deliveryOption: new FormControl<DeliveryType | string | null>(null, Validators.required),
		deliveryTariff: new FormControl<any>(null),
		apishipPickupTariff: new FormControl<any>(null),
		pickUpPointId: new FormControl<any>(null, Validators.required),
		address: new FormControl('', [Validators.required]),
		receiver: new FormGroup({
			firstName: new FormControl('', Validators.required),
			lastName: new FormControl('', Validators.required),
			patronymic: new FormControl('', Validators.required),
			phone: new FormControl('', [Validators.required, Validators.minLength(11)]),
			email: new FormControl('', [Validators.required, ...EmailValidators]),
		}),
		promocodeId: new FormControl(''),
		comment: new FormControl(''),
		fiasData: new FormControl<AddressSuggestionResponse | null>({}),
		paymentType: new FormControl(''),
	});

	cityForm = new FormGroup({
		selectedCity: new FormControl<any | null>(null),
		pickupSelectedPoint: new FormControl<PickUpPointResponse | null>(null),
		apishipSelectedPoint: new FormControl<ApishipPickUpPointsResponse | null>(null),
	});

	apishipProviders = new BehaviorSubject<ApishipDeliveryServicesWithMinimalCostDeliveryToDoor | null>(null);
	deliveryTariffs = new BehaviorSubject<ApishipTariffResponse[] | null>(null);
	apishipPickupTariffs = new BehaviorSubject<ApishipTariffResponse[] | null>(null);

	pickUpPoints: any = [];

	constructor(
		private formsManager: NgFormsManager,
		private http: HttpClient,
		private shopService: ShopService,
		private transloco: TranslocoService,
	) {
		this.formsManager.upsert('delivery', this.form, { persistState: true });
		this.form.patchValue({
			deliveryOption: null,
			promocodeId: '',
			apishipPickupTariff: null,
			pickUpPointId: null,
			deliveryTariff: null,
		});
		this.formsManager.upsert('city', this.cityForm, { persistState: true });
	}

	refreshCart(shopId: string, customPaymentTypeId?: string | null): void {
		this.getBasket(shopId, customPaymentTypeId).subscribe(cart => {
			this.productsInCart$.next(cart);
		});
	}

	get products() {
		return this.productsInCart$.value;
	}

	isCitySelected(): boolean {
		return this.cityForm.controls.selectedCity.value !== null;
	}

	set selectedCity(data: any) {
		this.cityForm.patchValue({
			selectedCity: data,
		});
	}

	get selectedCity() {
		return this.cityForm.controls.selectedCity.value;
	}

	set pickupSelectedPoint(data: PickUpPointResponse | null) {
		this.cityForm.patchValue({
			pickupSelectedPoint: data,
		});
	}

	get pickupSelectedPoint() {
		return this.cityForm.controls.pickupSelectedPoint.value;
	}

	set apishipSelectedPoint(data: any) {
		this.cityForm.patchValue({
			apishipSelectedPoint: data,
		});
	}

	get apishipSelectedPoint() {
		return this.cityForm.controls.apishipSelectedPoint.value;
	}

	changeAmountCart(amount: number, shopId: string, positionId?: string, isDigital?: boolean): void {
		if (!positionId) {
			return;
		}

		const data: any = {
			amount: amount,
		};

		if (isDigital) {
			data.digitalGoodsId = positionId;
		} else {
			data.positionId = positionId;
		}

		window.Telegram.WebApp.MainButton.enable();
		this.updateBasket(shopId, data).subscribe(() => {
			this.refreshCart(shopId);
		});
	}

	getBasket(shopId: string, customPaymentTypeId?: string | null): Observable<BasketResponse> {
		const params: any = {
			promocodeId: this.promocodeId ? this.promocodeId : '',
		}

		if (customPaymentTypeId && customPaymentTypeId !== OrderCreatedResponse.paymentType.TELEGRAM && customPaymentTypeId !== OrderCreatedResponse.paymentType.TINKOFF) {
			params.customPaymentTypeId = customPaymentTypeId;
		}

		return this.http.get(`webapp/shops/${shopId}/basket`, {
			headers: {
				'Accept-Language': this.transloco.getActiveLang(),
			},
			params,
		});
	}

	getBasketSettings(shopId: string): Observable<BasketSettingsResponse> {
		return this.http.get<BasketSettingsResponse>(`webapp/shops/${shopId}/basket/settings`);
	}

	updateBasket(shopId: string, data: BasketItemRequest): Observable<unknown> {
		return this.http.post(`webapp/shops/${shopId}/basket`, data);
	}

	resetBasket(shopId: string): Observable<unknown> {
		return this.http.delete(`webapp/shops/${shopId}/basket`);
	}

	ngOnDestroy(): void {
		this.destroy$.next(null);
		this.destroy$.complete();
		this.formsManager.unsubscribe('onboarding');
	}

	createOrder(
		shopId: string,
		data: OrderData,
		fiasData: AddressSuggestionResponse | null,
	): Observable<OrderCreatedResponse> {
		const body: OrderData = {
			...data,
			address: {
				fiasId: fiasData?.fiasId!,
				address: data.address,
			},
		};
		if (
			data.deliveryOption === OrderRequest.deliveryOption.PICK_UP ||
			this.shopService.shopMoneySign.value !== '₽' ||
			body.apishipDelivery?.productDeliveryType === productDeliveryType.DELIVERY_TO_PVZ ||
			this.shopService.isShopDigital()
		) {
			delete body.address;
		}
		if (data.deliveryOption !== OrderRequest.deliveryOption.PICK_UP) {
			delete body.pickUpPointId;
		}
		if (body.fiasData) {
			delete body.fiasData;
		}
		if (body.comment === '') {
			delete body.comment;
		}

		delete body.deliveryTariff;
		delete body.apishipPickupTariff;

		return this.http.post<OrderCreatedResponse>(`webapp/shops/${shopId}/orders`, body);
	}

	createNoPaymentOrder(shopId: string, orderId: string): Observable<any> {
		return this.http.post<any>(`webapp/shops/${shopId}/orders/confirm`, orderId);
	}

	getShopDeliveryOptions(shopId: string): Observable<DeliveryInfoResponse> {
		return this.http.get<DeliveryInfoResponse>(`webapp/shops/${shopId}/deliveries`);
	}

	getShopDeliveryPickUpPoints(shopId: string, cityFiasId: string): Observable<PickUpPointResponse[]> {
		return this.http.get<PickUpPointResponse[]>(`webapp/shops/${shopId}/deliveries/pick-up-points`, {
			params: { cityFiasId },
		});
	}

	getApishipProviders(
		shopId: string,
		cityGuid: string,
	): Observable<ApishipDeliveryServicesWithMinimalCostDeliveryToDoor> {
		return this.http.get<ApishipDeliveryServicesWithMinimalCostDeliveryToDoor>(
			`webapp/shops/${shopId}/deliveries/apiship/delivery-services`,
			{ params: { cityGuid } },
		);
	}

	sendNotify(shopId: string, orderId: string) {
		return this.http.get(`webapp/shops/${shopId}/orders/${orderId}/notify-client`);
	}

	checkPaymentsExists(shopId: string): Observable<PaymentInfoResponse> {
		return this.http.get(`webapp/shops/${shopId}/payments`);
	}

	getCityList(query: string) {
		return this.http.get(`addresses`, { params: { query, level: 'CITY' } });
	}

	getDeliveryAddress(query: string, fiasId: string): Observable<AddressSuggestionResponse[]> {
		return this.http.get<AddressSuggestionResponse[]>(`addresses`, { params: { query, fiasId } });
	}

	getDeliveryTariffs(shopId: string, address: string): Observable<ApishipTariffResponse[]> {
		return this.http.get<ApishipTariffResponse[]>(
			`webapp/shops/${shopId}/deliveries/apiship/delivery-to-door/tariffs`,
			{ params: { address } },
		);
	}

	getPickupTariffs(shopId: string, providerKey: string, cityGuid: string, pickPointId: string): Observable<any> {
		const params = new HttpParams()
			.set('cityGuid', cityGuid)
			.set('providerKey', providerKey)
			.set('pickPointId', pickPointId);

		return this.http.get<any>(`webapp/shops/${shopId}/deliveries/apiship/pick-up-points/tariffs`, { params });
	}

	getApishipPickupPoints(
		shopId: string,
		providerKey: string,
		cityGuid: string,
	): Observable<ApishipPickUpPointsResponse[]> {
		return this.http.get<ApishipPickUpPointsResponse[]>(
			`webapp/shops/${shopId}/deliveries/apiship/pick-up-points`,
			{ params: { providerKey, cityGuid } },
		);
	}

	async getFullPrice(
		shopId: string,
		deliveryCost: number,
		promocodeId: string | null,
		customPaymentTypeId: string | null,
	) {
		const params: any = {
			deliveryCost
		}

		if (this.promocodeId !== null) {
			params.promocodeId = promocodeId;
		}

		if (customPaymentTypeId !== null) {
			params.customPaymentTypeId = customPaymentTypeId;
		}

		const result = this.http.get<any>(
			`webapp/shops/${shopId}/basket/calculate`,
			{ params },
		);

		return firstValueFrom(result);
	}
}
