<ng-container *transloco="let t">
	<div class="item" [class.active]="activeSort === null">
		<div class="item__line" (click)="select(null)">
			<div class="item__name">По умолчанию</div>
			<div class="checkbox"></div>
		</div>
	</div>

	<div
		*ngFor="let sort of sorting.value"
		class="item"
		[class.active]="activeSort && activeSort.param === sort.param && activeSort.dir === sort.dir"
	>
		<div class="item__line" (click)="select(sort)">
			<div class="item__name">{{ sort.name }}</div>
			<div class="checkbox"></div>
		</div>
	</div>

	<ng-container>
		<tk-main-button text="Выбрать" [click]="choose"></tk-main-button>
	</ng-container>
</ng-container>
