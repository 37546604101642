import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	standalone: true,
	selector: '[tkInputTrim]',
})
export class InputTrimDirective {
	constructor(private ngControl: NgControl) {}

	@HostListener('change', ['$event.target.value'])
	onChange(value: unknown) {
		this.ngControl.control?.setValue(typeof value === 'string' ? value.trim() : value);
	}
}
