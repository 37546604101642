import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

export interface TkContextWithImplicit<T> {
	$implicit: T;
}

/**
 * @internal
 */
export class LetContext<T> implements TkContextWithImplicit<T> {
	constructor(private readonly internalDirectiveInstance: LetDirective<T>) {}

	get $implicit(): T {
		return this.internalDirectiveInstance.tkLet;
	}

	get tkLet(): T {
		return this.internalDirectiveInstance.tkLet;
	}
}

@Directive({
	selector: '[tkLet]',
})
export class LetDirective<T> {
	@Input()
	tkLet!: T;

	constructor(
		@Inject(ViewContainerRef) viewContainer: ViewContainerRef,
		@Inject(TemplateRef) templateRef: TemplateRef<LetContext<T>>,
	) {
		viewContainer.createEmbeddedView(templateRef, new LetContext<T>(this));
	}

	static ngTemplateContextGuard<T>(dir: LetDirective<T>, ctx: any): ctx is LetDirective<T> {
		return true;
	}
}
