<button
	*ngFor="let color of colors"
	class="color"
	[class.color--active]="value && color.id === value.id"
	[class.color--with-shadow]="withShadow"
	[class.color--disabled]="isDisabled"
	[class.color--empty]="!color.amountPositions"
	[style.background-color]="color.rgbCode"
	(click)="valueChange.emit(color)"
>
	<div *ngIf="!isDisabled && !color.amountPositions">
		<tk-icon icon="empty" size="16"></tk-icon>
	</div>
</button>
