/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Запрос для добавления промокода
 */
export type PromocodeRequest = {
    /**
     * Идентификатор промокода
     */
    id: string;
    /**
     * Название промокода
     */
    promocodeName: string;
    /**
     * Тип скидки
     */
    discountType: PromocodeRequest.discountType;
    /**
     * Размер скидки
     */
    discountValue: number;
    /**
     * Дата начала действия промокода
     */
    validDateFrom: string;
    /**
     * Дата окончания действия промокода
     */
    validDateTo: string;
    /**
     * Минимальная сумма заказа для применения промокода
     */
    minOrderSum?: number;
    notApplicableDiscounted: boolean;
};

export namespace PromocodeRequest {

    /**
     * Тип скидки
     */
    export enum discountType {
        DISCOUNT = 'DISCOUNT',
        PERCENT = 'PERCENT',
    }


}

