import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { TelegramButtonDirective } from '@components/ui/telegram-button.directive';

@Directive({
	selector: '[tkMainButton], tk-main-button',
})
export class MainButtonDirective extends TelegramButtonDirective implements OnInit, OnDestroy {
	@Input() text = '';

	ngOnInit() {
		window.Telegram.WebApp.MainButton.setText(this.text);
		window.Telegram.WebApp.MainButton.show();
		window.Telegram.WebApp.MainButton.onClick(this.callback);
	}

	ngOnDestroy() {
		window.Telegram.WebApp.MainButton.hide();
		window.Telegram.WebApp.MainButton?.offClick(this.callback);
	}
}
