import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'tk-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
	@Input() icon!: string;
	@Input() width: number | string = 24;
	@Input() height: number | string = 24;

	@Input() set size(value: number | string) {
		this.width = value;
		this.height = value;
	}
}
