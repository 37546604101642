/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Вариант доставки
 */
export type DeliveryOptionRequest = {
    /**
     * Тип доставки
     */
    deliveryOption: DeliveryOptionRequest.deliveryOption;
    /**
     * вкл/выкл
     */
    isEnabled: boolean;
    /**
     * стоимость доставки
     */
    deliveryCost?: number;
    /**
     * время доставки
     */
    deliveryPeriod?: string;
    /**
     * минимальная сумма заказа, с которого доставка бесплатная
     */
    minOrderCostForFreeDelivery?: number;
};

export namespace DeliveryOptionRequest {

    /**
     * Тип доставки
     */
    export enum deliveryOption {
        PICK_UP = 'PICK_UP',
        DELIVERY = 'DELIVERY',
        APISHIP = 'APISHIP',
        NONE = 'NONE',
    }


}

