<ng-container *transloco="let t">
	<div class='wrap'>
		<div (click)='close()' class='close'>
			<tk-icon icon="close" size="24"></tk-icon>
		</div>
		<ng-container *ngIf="photos.length > 0 && !(loading$ | async)">
				<swiper #swiper class="swiper"
				        [spaceBetween]="30"
				        [pagination]="true"
				        [zoom]="true"
				>
					<ng-template *ngFor="let photo of photos" swiperSlide>
						<div class='swiper-zoom-container'>
							<img [src]="photo.file!.id | getFile | async" alt height="450" class="swiper__photo" />
						</div>
					</ng-template>
				</swiper>
		</ng-container>
	</div>

</ng-container>
