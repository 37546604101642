import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	CurrencyTypeResponse,
	ProductTypeResponse,
	ShopInfoResponse,
	ShopOrderFieldResponse,
	ShopResponse,
	SubscriptionFeatureResponse,
} from '@models/generated/api';
import { HttpClient } from '@angular/common/http';
import productType = ShopResponse.productType;

@Injectable({
	providedIn: 'root',
})
export class ShopService {
	shopMoneySign = new BehaviorSubject<string | null>(null);
	shopProductType = new BehaviorSubject<productType | null>(null);
	subscriptionFeatures = new BehaviorSubject<SubscriptionFeatureResponse[] | null>([
		{ code: SubscriptionFeatureResponse.code.NO_ADS, available: true },
	]);
	shopId: string | null = null;

	constructor(private http: HttpClient) {}

	setMoneySign(res: CurrencyTypeResponse) {
		switch (res.currencyType) {
			case CurrencyTypeResponse.currencyType.RUB: {
				this.shopMoneySign.next('₽');
				break;
			}
			case CurrencyTypeResponse.currencyType.USD: {
				this.shopMoneySign.next('$');
				break;
			}
			case CurrencyTypeResponse.currencyType.UZS: {
				this.shopMoneySign.next('сўм');
				break;
			}
		}
	}

	getShopInfo(shopId: string): Observable<ShopInfoResponse> {
		return this.http.get(`webapp/shops/${shopId}/shop-info`);
	}

	getShopOrderFields(shopId: string): Observable<ShopOrderFieldResponse[]> {
		return this.http.get<ShopOrderFieldResponse[]>(`webapp/shops/${shopId}/order-fields`);
	}

	getOrderInfo(shopId: string, orderId: string) {
		return this.http.get<ShopOrderFieldResponse[]>(`webapp/shops/${shopId}/orders/${orderId}`);
	}

	checkPromocode(shopId: string, promocodeId: string): Observable<ShopInfoResponse> {
		return this.http.get(`webapp/shop/${shopId}/promocodes`, {
			params: { value: promocodeId },
		});
	}

	isShopDigital(): boolean {
		return this.shopProductType.value === productType.DIGITAL;
	}
}
