/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Служба доставки Apiship
 */
export type ApishipDeliveryServiceRequest = {
    /**
     * Служба доставки Apiship
     */
    providerKey: string;
    /**
     * Тип забора груза
     */
    productTransferType?: ApishipDeliveryServiceRequest.productTransferType;
    /**
     * Идентификатор пункта выдачи
     */
    pickPointId?: number;
    /**
     * Адрес пункта выдачи
     */
    pickPointAddress?: string;
};

export namespace ApishipDeliveryServiceRequest {

    /**
     * Тип забора груза
     */
    export enum productTransferType {
        FROM_WAREHOUSE = 'FROM_WAREHOUSE',
        FROM_DELIVERY_SERVICE_WAREHOUSE = 'FROM_DELIVERY_SERVICE_WAREHOUSE',
    }


}

