import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { ShopService } from '@services/shop.service';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
	constructor(private authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(this.addToken(request)).pipe(
			catchError((err: any) => {
				if (err.status === 401) {
					this.authService.token = '';
				}

				return throwError(err);
			}),
		);
	}

	private addToken(request: HttpRequest<any>): HttpRequest<any> {
		return request.clone({
			headers: this.authService.token
				? request.headers.set('Authorization', this.authService.token)
				: request.headers,
		});
	}
}
