import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '@services/cart.service';
import { ColorResponse, ProductForCatalogWebAppResponse } from '@models/generated/api';
import { ProductsService } from '@services/products.service';
import { ShopService } from '@services/shop.service';

@Component({
	selector: 'tk-product-card',
	templateUrl: './product-card.component.html',
	styleUrls: ['./product-card.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent implements OnChanges {
	@Input() product!: ProductForCatalogWebAppResponse;

	activeColor?: ColorResponse;

	constructor(
		private cartService: CartService,
		private route: ActivatedRoute,
		private router: Router,
		private productsService: ProductsService,
		public shopService: ShopService,
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['product']) {
			this.activeColor = this.product.colors?.[0];
		}
	}

	get hasColorPicker(): boolean {
		return Boolean(
			(this.product.colors && this.product.colors?.length > 1) ||
				(this.product.colors?.length === 1 && this.product.colors[0].id !== 'DEFAULT'),
		);
	}

	get price(): number {
		if (this.product.price && this.product.discount) {
			return this.product.price - this.product.price * (this.product.discount / 100);
		}
		return this.product.price || 0;
	}

	navigateToProduct(): void {
		this.productsService.currProductId.next(this.product.productId!);
		this.router.navigate([this.product.productId], { relativeTo: this.route });
	}
}
