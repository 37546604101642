/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentCustomDtoResponse = {
    id?: string;
    name?: string;
    description?: string;
    discountValue?: number;
    discountType?: PaymentCustomDtoResponse.discountType;
};

export namespace PaymentCustomDtoResponse {

    export enum discountType {
        DISCOUNT = 'DISCOUNT',
        PERCENT = 'PERCENT',
    }


}

