<ng-container *transloco="let t">
	<div class="item" [class.active]="activeCategory === null">
		<div class="item__line" (click)="select(null)">
			<div class="item__name">{{ t('catalog.all_products') }}</div>
			<div class="checkbox"></div>
		</div>
	</div>

	<ng-container *ngTemplateOutlet="Recursion; context: { cats: categories.value?.categories }"></ng-container>

	<ng-container>
		<tk-main-button [text]="t('catalog.view')" [click]="choose"></tk-main-button>
	</ng-container>

	<ng-template #Recursion let-cats="cats">
		<div
			class="item"
			*ngFor="let category of cats"
			[class.active]="category.categoryId === activeCategory?.categoryId"
			[id]="category.categoryId"
		>
			<ng-container *ngIf="shouldShowCategory(category)">
				<div
					class="item__line"
					[classList]="'item__line item__line--depth-' + category.depth"
					(click)="select(category)"
				>
					<div class="item__name">{{ category.categoryName }}</div>
					<div class="checkbox"></div>
				</div>
				<ng-container>
					<ng-container *ngTemplateOutlet="Recursion; context: { cats: category.children }"></ng-container>
				</ng-container>
			</ng-container>
		</div>
	</ng-template>
</ng-container>
