import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TranslationService {
	translate = new BehaviorSubject<any>(null);

	constructor(private translocoService: TranslocoService) {}

	setTranslation() {
		this.translate.next(this.translocoService.getTranslation(this.translocoService.getActiveLang()));
	}
}
