import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '@services/cart.service';
import { ColorResponse, ProductForCatalogWebAppResponse, VendorCodeResponse } from '@models/generated/api';
import { ProductsService } from '@services/products.service';
import { ShopService } from '@services/shop.service';
import { BehaviorSubject } from 'rxjs';
import { SwiperComponent } from 'swiper/angular';

@Component({
	selector: 'tk-product-card',
	templateUrl: './product-card.component.html',
	styleUrls: ['./product-card.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardComponent implements OnChanges {
	@Input() product!: ProductForCatalogWebAppResponse;
	@ViewChild('photo') photo?: HTMLImageElement;
	loading$ = new BehaviorSubject<boolean>(true);

	activeColor?: ColorResponse;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private productsService: ProductsService,
		public shopService: ShopService,
	) {
		if (!this.product?.photo) {
			this.loading$.next(false);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['product']) {
			this.activeColor = this.product.colors?.[0];
		}
	}

	get hasColorPicker(): boolean {
		return Boolean(
			(this.product.colors && this.product.colors?.length > 1) ||
				(this.product.colors?.length === 1 && this.product.colors[0].id !== 'DEFAULT'),
		);
	}

	get price(): number {
		if (this.product.price && this.product.discount) {
			return this.product.price - this.product.price * (this.product.discount / 100);
		}
		return this.product.price || 0;
	}

	navigateToProduct(): void {
		this.productsService.currProductId.next(this.product.productId!);
		this.router.navigate([this.product.productId], { relativeTo: this.route });
	}

	uploadImageCallback = (blob: any) => {
		const photo = document.getElementById("photo");
		photo?.setAttribute('src', blob);
		this.loading$.next(false);
	}
}
