import { Directive, OnDestroy, OnInit } from '@angular/core';
import { TelegramButtonDirective } from '@components/ui/telegram-button.directive';

@Directive({
	selector: '[tkBackButton], tk-back-button',
})
export class BackButtonDirective extends TelegramButtonDirective implements OnInit, OnDestroy {
	ngOnInit() {
		window.Telegram.WebApp.BackButton.show();
		window.Telegram.WebApp.BackButton.onClick(this.callback);
	}

	ngOnDestroy() {
		window.Telegram.WebApp.BackButton.hide();
		window.Telegram.WebApp.BackButton?.offClick(this.callback);
	}
}
