import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CurrencyTypeResponse, TokenResponse, ValidateDataRequest } from '@models/generated/api';
import { LocalStorage } from '@utils/local-storage';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	@LocalStorage() token?: string;
	@LocalStorage() webappInitData?: string;
	openProductPage: any = false;

	constructor(private http: HttpClient) {}

	signIn(shopId: string, data: ValidateDataRequest): Observable<TokenResponse> {
		return this.http.post(`webapp/sign-in/shop/${shopId}`, data);
	}

	authorize(data: TokenResponse, webappInitData: string) {
		this.token = data.accessToken;
		this.webappInitData = webappInitData;
	}
}
