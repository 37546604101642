import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	CategoryResponse,
	CategoryTreeResponse,
	OrderingResponse,
	OrderResponse,
	ProductForCatalogWebAppResponse,
	ProductResponse,
} from '@models/generated/api';
import { PaginatedResponse } from '@models/pagination.model';
import { WithPagingParams } from '@models/paging.interface';

@Injectable({
	providedIn: 'root',
})
export class ProductsService {
	products$ = new BehaviorSubject<PaginatedResponse<ProductForCatalogWebAppResponse>>({});

	currProductId = new BehaviorSubject<string>('');
	currProductPage = new BehaviorSubject<number>(0);

	categories = new BehaviorSubject<CategoryTreeResponse | null>(null);
	activeCategory = new BehaviorSubject<CategoryResponse | null>(null);
	newCategory = new BehaviorSubject<boolean>(false);

	sortings = new BehaviorSubject<OrderingResponse[] | null>(null);
	activeSort = new BehaviorSubject<OrderingResponse | null>(null);
	newSort = new BehaviorSubject<boolean>(false);

	searchStr = new BehaviorSubject<string | null>(null);

	constructor(private http: HttpClient) {}

	getProducts(
		shopId: string,
		params: WithPagingParams,
		cat: any,
		search: string,
		sort: OrderingResponse | null,
	): Observable<ProductForCatalogWebAppResponse[]> {
		const prms: any = {
			...params,
		};

		if (cat.value) {
			prms.categoryId = cat.value.categoryId;
		}

		if (search) {
			prms.search = search;
		}

		if (sort?.param) {
			prms.sort = sort!.param + ',' + sort!.dir;
		}

		return this.http.get<ProductForCatalogWebAppResponse[]>(`webapp/shops/${shopId}/products`, {
			params: prms,
		});
	}

	getCategories(shopId: string): Observable<CategoryTreeResponse> {
		return this.http.get<CategoryTreeResponse>(`webapp/shops/${shopId}/categories`);
	}

	getSort(shopId: string): Observable<OrderingResponse[]> {
		return this.http.get<OrderingResponse[]>(`product-ordering`, {
			params: { shopId },
		});
	}

	getProduct(shopId: string, productId: string): Observable<ProductResponse> {
		return this.http.get<ProductResponse>(`webapp/shops/${shopId}/products/${productId}`);
	}
}
