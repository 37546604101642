import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import SwiperCore, { Pagination, Zoom } from 'swiper';
import { VendorCodePhotoResponse } from '@models/generated/api';
import { SwiperComponent } from 'swiper/angular';
import { Dialog } from '@angular/cdk/dialog';
import { ZoomModalComponent } from '@app/modals/zoom-modal/zoom-modal.component';
// import { Fancybox } from '@fancyapps/ui';

SwiperCore.use([Pagination, Zoom]);

@Component({
	selector: 'tk-product-photo',
	templateUrl: './product-photo.component.html',
	styleUrls: ['./product-photo.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPhotoComponent {
	@Input() photos: Array<VendorCodePhotoResponse> = [];
	isZoomOpened = false;

	@ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

	constructor(private dialog: Dialog, private cdr: ChangeDetectorRef,) {
	}

	openZoom(): void {
		this.isZoomOpened = true;
		this.dialog.open(ZoomModalComponent,{
			backdropClass: "dialogBackdrop",
			data: {
				photos: this.photos
			},
		}).closed.subscribe({
			next: () => {
				this.isZoomOpened = false;
				this.cdr.markForCheck();
			},
		});
	}

	ngOnChanges(): void {
		// this.swiper?.swiperRef.slideTo(0, 0);
	}
}
