/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Подписка
 */
export type SubscriptionResponse = {
    /**
     * Идентификатор подписки
     */
    id?: string;
    /**
     * Название тарифа
     */
    planName?: string;
    /**
     * Дата начала подписки
     */
    startDate?: string;
    /**
     * Дата окончания подписки
     */
    finishDate?: string;
    /**
     * Сумма платежа
     */
    paymentSum?: number;
    /**
     * Статус подписки
     */
    status?: SubscriptionResponse.status;
    recurrent?: boolean;
    trial?: boolean;
};

export namespace SubscriptionResponse {

    /**
     * Статус подписки
     */
    export enum status {
        ACTIVE = 'ACTIVE',
        FINISHED = 'FINISHED',
        AWAIT_CONFIRM = 'AWAIT_CONFIRM',
        CONFIRM_FAILED = 'CONFIRM_FAILED',
        AWAIT_PAY = 'AWAIT_PAY',
        EXPIRED_PAY = 'EXPIRED_PAY',
    }


}

