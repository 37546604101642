import { Directive, Input, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
	selector: '[tkTelegramButton], tk-telegram-button',
})
export abstract class TelegramButtonDirective {
	@Input('tkRouterLink') routerLink?: any[] | null;
	@Input('click') clickHandler?: () => void;

	constructor(private router: Router, private ngZone: NgZone) {}

	callback = () => {
		this.ngZone.run(() => {
			if (this.routerLink) {
				this.router.navigate(this.routerLink);
			}
			if (this.clickHandler) {
				this.clickHandler();
			}
		});
	};
}
