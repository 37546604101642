/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Информация о доставке apiship
 */
export type ApishipDeliveryRequest = {
    /**
     * Код службы доставки
     */
    providerKey: string;
    /**
     * Тип доставки (до двери или до пункта выдачи)
     */
    productDeliveryType: ApishipDeliveryRequest.productDeliveryType;
    /**
     * Идентификатор пункта выдачи
     */
    pickPointId?: number;
    /**
     * Адрес пункта выдачи
     */
    pickUpPointAddress?: string;
    /**
     * Идентификатор тарифа
     */
    tariffId: number;
    /**
     * Стоимость доставки
     */
    deliveryCost: number;
};

export namespace ApishipDeliveryRequest {

    /**
     * Тип доставки (до двери или до пункта выдачи)
     */
    export enum productDeliveryType {
        DELIVERY_TO_DOOR = 'DELIVERY_TO_DOOR',
        DELIVERY_TO_PVZ = 'DELIVERY_TO_PVZ',
    }


}

