import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

export class FormUtils {
	static getControlErrors(control: AbstractControl): ValidationErrors | null {
		if (control instanceof FormControl) {
			return control.errors ?? null;
		}
		if (control instanceof FormGroup) {
			const groupErrors = control.errors;
			const formErrors: any = groupErrors ? { groupErrors } : {};
			Object.keys(control.controls).forEach(key => {
				const error = FormUtils.getControlErrors(control.get(key)!);
				if (error !== null) {
					formErrors[key] = error;
				}
			});
			return Object.keys(formErrors).length > 0 ? formErrors : null;
		}
		return null;
	}
}
