<ng-container *transloco="let t">
	<ng-container *tkLet="cartService.productsInCart$ | async as cart">
		<form [formGroup]="form" *ngIf="this.delivery?.deliveryOptions?.length || shopService.isShopDigital()">
			<section id="receiver" formGroupName="receiver">
				<h2>{{ t('order.beneficiary') }}</h2>
				<tk-form-field *ngIf="isFieldEnabled('Фамилия')">
					<input
						formControlName="lastName"
						tkInput
						tkInputTrim
						name="lastName"
						autocomplete="family-name"
						[placeholder]="t('order.surname')"
						id='lastName'
					/>
					<div
						class="error"
						*ngIf="form.get(['receiver', 'lastName'])?.touched && errors()?.receiver?.lastName?.required"
					>
						{{ t('order.required') }}
					</div>
				</tk-form-field>
				<tk-form-field>
					<input
						formControlName="firstName"
						tkInput
						tkInputTrim
						name="firstName"
						autocomplete="given-name"
						[placeholder]="t('order.name')"
						id='firstName'
					/>
					<div
						class="error"
						*ngIf="form.get(['receiver', 'firstName'])?.touched && errors()?.receiver?.firstName?.required"
					>
						{{ t('order.required') }}
					</div>
				</tk-form-field>
				<tk-form-field *ngIf="isFieldEnabled('Отчество')">
					<input
						formControlName="patronymic"
						tkInput
						tkInputTrim
						name="patronymic"
						autocomplete="middle-name"
						[placeholder]="t('order.patronymic')"
						id='patronymic'
					/>
					<div
						class="error"
						*ngIf="
							form.get(['receiver', 'patronymic'])?.touched && errors()?.receiver?.patronymic?.required
						"
					>
						{{ t('order.required') }}
					</div>
				</tk-form-field>
				<tk-form-field *ngIf="isFieldEnabled('Телефон')">
					<input
						formControlName="phone"
						name="phone"
						tkInput
						type="tel"
						autocomplete="tel"
						[placeholder]="t('order.phone_number')"
						[mask]="t('order.phone_mask')"
						id='phone'
					/>
					<div
						class="error"
						*ngIf="form.get(['receiver', 'phone'])?.touched && errors()?.receiver?.phone?.required"
					>
						{{ t('order.required') }}
					</div>
				</tk-form-field>
				<tk-form-field *ngIf="isFieldEnabled('Email')">
					<input
						formControlName="email"
						tkInput
						name="email"
						type="email"
						autocomplete="email"
						placeholder="Email"
						id='email'
					/>
					<div
						class="error"
						*ngIf="form.get(['receiver', 'email'])?.touched && errors()?.receiver?.email?.required"
					>
						{{ t('order.required') }}
					</div>
					<div
						class="error"
						*ngIf="
							form.get(['receiver', 'email'])?.touched &&
							(errors()?.receiver?.email?.pattern || errors()?.receiver?.email?.email)
						"
					>
						{{ t('order.address_invalid') }}
					</div>
				</tk-form-field>
			</section>

			<ng-container *ngIf="!shopService.isShopDigital()">
				<ng-container *ngIf="shopService.shopMoneySign.value === '₽'">
					<h2 class="head">Населенный пункт</h2>
					<div id='city' [ngClass]="{ 'err': cityErr, city: true }" (click)="openPickUpPoints()">
						<div class="flex">
							<div class="city__title">Город</div>
							<div class="flex">
								<div class="city__name">
									{{ cartService.selectedCity?.address ?? 'Не выбран' }}
								</div>
								<tk-icon size="16" icon="chevron-right"></tk-icon>
							</div>
						</div>
					</div>

					<ng-container *ngIf="loadingProviders$ | async">
						<div class="loader"><img src="assets/loader.svg" alt /></div>
					</ng-container>

					<ng-container
						*ngIf="
							cartService.selectedCity && !(loadingProviders$ | async) && !(loadingPickupPoints$ | async)
						"
					>
						<h2 class="head">Способ получения</h2>
						<section
							id="type"
							[ngClass]="{ 'err': deliveryErr }"
						>
							<tk-radio formControlName="deliveryOption">
								<tk-radio-option
									[value]="DeliveryType.Delivery"
									*ngIf="checkDeliveryOption(DeliveryType.Delivery)"
								>
									<div>
										<div>Доставка</div>
										<ng-container *ngIf="this.delivery?.deliveryPeriod">
											Стоимость:
											{{
												(cart$ | async)!.realPrice! <
												+this.delivery?.minOrderCostForFreeDelivery!
													? this.delivery!.deliveryCost + ' рублей'
													: 'Бесплатно'
											}}
											<br />
											Сроки: {{ this.delivery?.deliveryPeriod }}
										</ng-container>
									</div>
								</tk-radio-option>

								<tk-radio-option
									[value]="DeliveryType.Apiship"
									*ngIf="
										(cartService.apishipProviders | async)?.hasOwnProperty('minimalCostDeliveryToDoor') &&
										!isApishipLoading
									"
								>
									<div>
										<div>{{ t('order.delivery') }}</div>
										<div *ngIf="cartService.apishipProviders?.value?.hasOwnProperty('minimalCostDeliveryToDoor')">
											от {{ cartService.apishipProviders?.value?.minimalCostDeliveryToDoor }}
											{{ shopService.shopMoneySign.value }}
										</div>
									</div>
								</tk-radio-option>

								<tk-radio-option
									[value]="DeliveryType.PickUp"
									*ngIf="checkDeliveryOption(DeliveryType.PickUp) && !hidePickup && !isApishipLoading"
								>
									<div>
										<div>{{ t('order.pickup') }}</div>
										<div>Бесплатно</div>
									</div>
								</tk-radio-option>

								<ng-container *ngIf="checkDeliveryOption(DeliveryType.Apiship)">
									<tk-radio-option
										[value]="provider.providerKey"
										*ngFor="let provider of (cartService.apishipProviders | async)?.providers"
									>
										<div>
											<div>Пункт выдачи {{ getProviderName(provider.providerKey!) }}</div>
											<div>
												от {{ provider.minimalDeliveryCost }}
												{{ shopService.shopMoneySign.value }}
											</div>
										</div>
									</tk-radio-option>
								</ng-container>

								<ng-container *ngIf="isNoDelivery()">
									В данный город отсутствует доставка
								</ng-container>
							</tk-radio>
						</section>

						<ng-container
							*ngIf="
								(checkDeliveryOption(DeliveryType.Delivery) ||
									(checkDeliveryOption(DeliveryType.Apiship) &&
										(cartService.apishipProviders | async)?.hasOwnProperty('minimalCostDeliveryToDoor'))) &&
								(form.controls.deliveryOption.value === DeliveryType.Delivery ||
									form.controls.deliveryOption.value === DeliveryType.Apiship)
							"
						>
							<ng-container *ngIf="shopService.shopMoneySign.value === '₽' && !(loading$ | async)">
								<h2 class="head">{{ t('order.delivery_address') }}.</h2>
								<tk-form-field tkAutocompleteOrigin>
									<input
										class="address-input"
										formControlName="address"
										tkInput
										tkInputTrim
										type="text"
										[placeholder]="t('order.address')"
										(ngModelChange)="onChangeSearch()"
										autocomplete="off"
										id='origin'
										#origin
										(focus)="autocompleteFocus($event)"
										(blur)="autocompleteBlur()"
									/>

									<div
										class="error"
										*ngIf="form.get(['address'])?.touched && errors()?.address?.required"
									>
										{{ t('order.required') }}
									</div>

									<div class="error" *ngIf="errors()?.address?.fias_empty">
										{{ t('order.pickup_address') }}
									</div>
									<div class="error" *ngIf="errors()?.address?.fias_house">
										{{ t('order.must_contain') }}
									</div>
									<div class="error" *ngIf="fiasList?.length === 0">Адрес не найден</div>
									<ng-template
										cdkConnectedOverlay
										[cdkConnectedOverlayOffsetY]="-50"
										[cdkConnectedOverlayOrigin]="origin"
										[cdkConnectedOverlayOpen]="
											!!fiasList && fiasList.length > 0 && isDestinationFocused
										"
										cdkConnectedOverlayBackdropClass="backdrop--transparent"
										[cdkConnectedOverlayPositions]="overlayPositions"
									>
										<div class="list">
											<div class="item" *ngFor="let item of fiasList" (click)="selectEvent(item)">
												{{ item.address }}
											</div>
										</div>
									</ng-template>
								</tk-form-field>

								<ng-container
									*ngIf="
										this.form.controls.deliveryOption.value === DeliveryType.Apiship &&
										fiasSelected?.isHouse &&
										cartService.deliveryTariffs.value
									"
								>
									<h2>Сроки и стоимость</h2>

									<tk-radio formControlName="deliveryTariff">
										<tk-radio-option
											[value]="tariff.tariffId"
											*ngFor="let tariff of (cartService.deliveryTariffs | async)!"
										>
											<div>
												<div>{{ tariff.tariffName }}</div>
												<div>
													Срок доставки:
													{{ getDeliveryDate(tariff.daysMin!) | date: 'dd.MM' }} -
													{{ getDeliveryDate(tariff.daysMax!) | date: 'dd.MM' }}
												</div>
												<div>
													Стоимость: {{ tariff.deliveryCost
													}}{{ shopService.shopMoneySign.value }}
												</div>
											</div>
										</tk-radio-option>
									</tk-radio>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-container>

					<ng-container
						*ngIf="
							!(loading$ | async) &&
							(isSelectedDeliveryOptionApiship() ||
								this.form.controls.deliveryOption.value === DeliveryType.PickUp)
						"
					>
						<h2>Выберите пункт самовывоза</h2>

						<div
							id='map'
							[ngClass]="{ 'map': true, 'err': mapErr }"
							*ngIf="
								(!cartService.apishipSelectedPoint && isSelectedDeliveryOptionApiship()) ||
								(!cartService.pickupSelectedPoint &&
									form.controls.deliveryOption.value === DeliveryType.PickUp)
							"
						>
							<ya-map [center]="[55.751952, 37.600739]" [zoom]="9" (ready)="onMapReady($event)">
								<ya-placemark
									*ngFor="let point of mapPoints"
									[geometry]="point.geometry!"
									[properties]="point.properties!"
									[options]="point.options!"
								></ya-placemark>
							</ya-map>
						</div>

						<div class="selected-point" *ngIf="cartService.apishipSelectedPoint">
							<div class="selected-point__name">{{ cartService.apishipSelectedPoint.name }}</div>
							<div class="selected-point__address">{{ cartService.apishipSelectedPoint.address }}</div>
							<div class="selected-point__timetable">
								{{
									cartService.apishipSelectedPoint.timetable
										? cartService.apishipSelectedPoint.timetable![0]
										: null
								}}
							</div>
							<button class="selected-point__btn" (click)="clearSelectedApishsipPoint()">
								Выбрать другой
							</button>
						</div>

						<div class="selected-point" *ngIf="cartService.pickupSelectedPoint">
							<div class="selected-point__name">
								{{ cartService.pickupSelectedPoint.pickUpPointName }}
							</div>
							<div class="selected-point__address">
								{{ cartService.pickupSelectedPoint?.address?.addressFull }}
							</div>
							<button class="selected-point__btn" (click)="clearSelectedPickupPoint()">
								Выбрать другой
							</button>
						</div>

						<ng-container
							*ngIf="cartService.apishipSelectedPoint && (cartService.apishipPickupTariffs | async)"
						>
							<h2>Сроки и стоимость</h2>
							<section
								id='tarrif'
								[ngClass]="{ 'err': tarrifErr }"
							>
								<tk-radio formControlName="apishipPickupTariff">
									<tk-radio-option
										[value]="tariff.tariffId"
										*ngFor="let tariff of (cartService.apishipPickupTariffs | async)!"
									>
										<div>
											<div>{{ tariff.tariffName }}</div>
											<div>
												Срок доставки: {{ getDeliveryDate(tariff.daysMin!) | date: 'dd.MM' }} -
												{{ getDeliveryDate(tariff.daysMax!) | date: 'dd.MM' }}
											</div>
											<div>
												Стоимость: {{ tariff.deliveryCost }}{{ shopService.shopMoneySign.value }}
											</div>
										</div>
									</tk-radio-option>
								</tk-radio>
							</section>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="(loading$ | async) && !(loadingProviders$ | async)">
				<div class="loader"><img src="assets/loader.svg" alt /></div>
			</ng-container>

			<ng-container
				*ngIf="this.shopInfo?.paymentInfo.paymentTypes?.length"
			>
				<h2>Оплата</h2>

				<tk-radio formControlName="paymentType">
					<ng-container *ngIf='hasPaymentType(OrderCreatedResponse.paymentType.TELEGRAM)'>
						<tk-radio-option
							[value]="OrderCreatedResponse.paymentType.TELEGRAM"
						>
							<div>
								<div>Онлайн оплата</div>
							</div>
						</tk-radio-option>
					</ng-container>

					<ng-container *ngIf='hasPaymentType(OrderCreatedResponse.paymentType.TINKOFF)'>
						<tk-radio-option
							[value]="OrderCreatedResponse.paymentType.TINKOFF"
						>
							<div>
								<div>Онлайн оплата</div>
							</div>
						</tk-radio-option>
					</ng-container>


					<ng-container *ngIf='isHasCustomPayment'>
						<tk-radio-option
							[value]="payment.id"
							*ngFor="let payment of shopInfo.paymentInfo.customPayments"
						>
							<div>
								<div>{{ payment.name }}</div>
								<div>
									{{ payment.description }}
								</div>
							</div>
						</tk-radio-option>
					</ng-container>

				</tk-radio>
			</ng-container>

			<h2 *ngIf="isFieldEnabled('Промокод')">Промокод:</h2>
			<tk-form-field class="promocode" *ngIf="isFieldEnabled('Промокод')">
				<input
					oninput="this.value = this.value.toUpperCase()"
					formControlName="promocodeId"
					tkInput
					placeholder="Введите промокод"
					(ngModelChange)="onPromocodeChange()"
				/>
				<div
					*ngIf="!(loadingPromocode$ | async) && !isPromocodeActive"
					class="promocode__btn"
					(click)="activatePromocode()"
				>
					Применить
				</div>
				<div *ngIf="loadingPromocode$ | async" class="promocode__loader">
					<img src="assets/loader.svg" alt />
				</div>
				<div *ngIf="isPromocodeActive" class="promocode__cancel" (click)="cancelPromocode()">
					<tk-icon icon="close" size="22"></tk-icon>
				</div>
			</tk-form-field>
			<div *ngIf="promocodeErrorMessage" class="promocode-text promocode-text__red">
				{{ promocodeErrorMessage }}
			</div>
			<div *ngIf="isPromocodeActive" class="promocode-text">Промокод активирован</div>

			<h2 *ngIf="isFieldEnabled('Комментарии к заказу')">{{ t('order.comment') }}:</h2>
			<tk-form-field *ngIf="isFieldEnabled('Комментарии к заказу')">
				<input formControlName="comment" tkInput [placeholder]="t('order.write_details')" />
			</tk-form-field>
		</form>

		<div class="cart-info">
			<div class="cart-info__item">
				<div>Общая стоимость:</div>
				<div>{{ (cart$ | async)!.price! }} {{ shopService.shopMoneySign.value }}</div>
			</div>
			<div
				*ngIf="
					(isSelectedDeliveryOptionDelivery() &&
						(cart$ | async)!.realPrice! > +this.delivery?.minOrderCostForFreeDelivery!) || this.form.controls.deliveryOption.value === DeliveryType.PickUp
					else elseBlock
				"
			>
				<div class="cart-info__item">
					<div>Доставка:</div>
					<div>Бесплатно</div>
				</div>
			</div>
			<ng-template #elseBlock>
				<div class="cart-info__item" *ngIf="Number.isFinite(getDeliveryCost()) && getDeliveryCost() !== 0">
					<div>Доставка:</div>
					<div>{{ getDeliveryCost() }} {{ shopService.shopMoneySign.value }}</div>
				</div>
			</ng-template>

			<div *ngIf="(cart$ | async)!.price! !== (cart$ | async)!.realPrice!" class="cart-info__item">
				<div>Скидка:</div>
				<div>
					{{ '-' }}{{ ((cart$ | async)!.price! - (cart$ | async)!.realPrice!).toFixed(2) }}
					{{ shopService.shopMoneySign.value }}
				</div>
			</div>
			<div class="cart-info__item">
				<div>Итого к оплате:</div>
				<div *ngIf="Number.isFinite(getDeliveryCost()) && getDeliveryCost() !== 0">
					{{ (finalPrice | async) }} {{ shopService.shopMoneySign.value }}
				</div>
				<div *ngIf="!(Number.isFinite(getDeliveryCost()) && getDeliveryCost() !== 0)">
					{{ (cart$ | async)!.realPrice! }} {{ shopService.shopMoneySign.value }}
				</div>

			</div>
		</div>


		<tk-main-button
			[text]="
				cartService.isWithoutPayment
					? 'Оформить заказ'
					: t('order.buy') +
					  (getDeliveryCost() ? ( ' ' +
					  finalPrice.value +
					  ' ' +
					  shopService.shopMoneySign.value) : ' ')
			"
			[click]="openInvoice"
		></tk-main-button>

	</ng-container>
	<ng-container *ngIf="!this.delivery?.deliveryOptions?.length && !shopService.isShopDigital()">
		Нет способов доставки
	</ng-container>
	<div class="policy" *ngIf="this.delivery?.deliveryOptions?.length">
		{{ t('order.agree_text') }}
		<a
			[href]="
				shopId === '31f5da3a-6868-4eca-8b0f-d96299135912' ? 'https://telegra.ph/Politika-v-otnoshenii-obrabotki-personalnyh-dannyh-03-06-3' : '/assets/TgShop_Политика_конфиденциальности_2022_12_05.pdf'"
			target="_blank"
			>{{ t('order.agree_href') }}</a
		>
	</div>
</ng-container>
