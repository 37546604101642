/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Статус отправки рассылки
 */
export enum NewsletterStatus {
    SENT = 'SENT',
    PLANNED = 'PLANNED',
    CANCELLED = 'CANCELLED',
}
