<ng-container *transloco="let t">
	<div *ngIf="count" class="counter">
		<button tkButton (click)="onDecrement($event)" class="counter__button">
			<tk-icon icon="minus" size="12"></tk-icon>
		</button>
		<span class="counter__count">{{ count }}</span>
		<button tkButton (click)="onIncrement($event)" class="counter__button">
			<tk-icon icon="plus" size="12"></tk-icon>
		</button>
	</div>

	<ng-container *ngIf="!count">
		<button *ngIf="currentAmount" tkButton (click)="onIncrement($event)" class="add-button add-button--big">
			<tk-icon icon="plus" [size]="12" class="add-button__icon"></tk-icon>
			{{ t('catalog.add_to_card') }}
		</button>

		<button *ngIf="!currentAmount" tkButton type="button" class="add-button empty-button">
			{{ t('catalog.out_of_stock') }}
		</button>
	</ng-container>
</ng-container>
