/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Возможности магазина по подписке
 */
export type SubscriptionFeatureResponse = {
    /**
     * Код возможности
     */
    code?: SubscriptionFeatureResponse.code;
    available?: boolean;
};

export namespace SubscriptionFeatureResponse {

    /**
     * Код возможности
     */
    export enum code {
        PRODUCTS_QUANTITY = 'PRODUCTS_QUANTITY',
        FOLLOWERS_QUANTITY = 'FOLLOWERS_QUANTITY',
        CATALOG = 'CATALOG',
        PAYMENT_INTEGRATION = 'PAYMENT_INTEGRATION',
        DIGITAL = 'DIGITAL',
        DELIVERY_INTEGRATION = 'DELIVERY_INTEGRATION',
        ANALYTICS = 'ANALYTICS',
        CRM_INTEGRATION = 'CRM_INTEGRATION',
        MANUAL_IMPORT = 'MANUAL_IMPORT',
        NO_ADS = 'NO_ADS',
        PRIORITY_SUPPORT = 'PRIORITY_SUPPORT',
    }


}

