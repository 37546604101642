import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DestroyService } from '@services/destroy.service';
import { LoadingService } from '@services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { ShopService } from '@services/shop.service';
import { TranslocoService } from '@ngneat/transloco';
import { CartService } from '@services/cart.service';
import { currencyLangBinding } from '@pages/shop/shop.component';

@Component({
	selector: 'tk-order-success',
	templateUrl: './order-success.component.html',
	styleUrls: ['./order-success.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
})
export class OrderSuccessComponent {
	shopId;

	constructor(
		public route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		public shopService: ShopService,
		private translocoService: TranslocoService,
		public cartService: CartService,
	) {
		this.shopId = this.route.snapshot.params['shopId'];

		console.log("init")
		window.Telegram.WebApp.MainButton.setText('Вернуться в магазин');
		window.Telegram.WebApp.MainButton.show();
		window.Telegram.WebApp.BackButton.hide();

		if (!this.authService.token) {
			this.authService.signIn(this.shopId, { webappInitData: window.Telegram.WebApp.initData }).subscribe({
				next: response => {
					this.authService.authorize(response, window.Telegram.WebApp.initData);
					this.shopService.getShopInfo(this.shopId).subscribe({
						next: res => {
							this.shopService.subscriptionFeatures.next(res.subscriptionFeatures!);

							this.translocoService.setActiveLang(
								currencyLangBinding[res.currencyType!.currencyType || 'RUB'],
							);
							this.shopService.setMoneySign(res.currencyType!);

							this.cartService.isWithoutPayment = res.paymentInfo!.withoutPayment!;
							this.cartService.paymentAvailable = !!res?.paymentInfo!.paymentTypes?.length && !res?.paymentInfo!.paymentTypes?.find(i => i === "NONE");

							this.shopService.shopProductType.next(res.productType!.productType!);
						},
					});
				},
				error: () => {
					window.Telegram.WebApp.showAlert('Oшибка');
				},
			});
		}
	}

	goToProducts = () => {
		this.router.navigate([`shops`, this.shopId, 'products']);
	};
}
