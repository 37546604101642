import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { CurrencyTypeResponse } from '@models/generated/api';
import { BehaviorSubject } from 'rxjs';
import { ShopService } from '@services/shop.service';
import { TranslationService } from '@services/translation.service';
import { CartService } from '@services/cart.service';

export const currencyLangBinding = {
	'RUB': 'ru',
	'USD': 'en',
	'UZS': 'uz',
};

@Component({
	selector: 'tk-shop',
	templateUrl: './shop.component.html',
	styleUrls: ['./shop.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopComponent {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private authService: AuthService,
		private translocoService: TranslocoService,
		private shopService: ShopService,
		private translationService: TranslationService,
		private cartService: CartService,
	) {
		this.signIn();
	}

	private signIn(): void {
		const shopId = this.route.snapshot.params['shopId'];
		this.shopService.shopId = shopId;

		const tgWebAppStartParam: string | null = this.route.snapshot.queryParamMap.get(`tgWebAppStartParam`);

		if (tgWebAppStartParam !== null) {
			if (tgWebAppStartParam.includes(`productId`)) {
				this.router.navigate([`shops`, shopId, `products`, tgWebAppStartParam.replace(`productId`, ``)]);
				return;
			}
		}

		!this.router.url.includes('/products/') &&
			this.authService.signIn(shopId, { webappInitData: window.Telegram.WebApp.initData }).subscribe({
				next: response => {
					this.authService.authorize(response, window.Telegram.WebApp.initData);
					this.getShopInfo(shopId);
				},
				error: err => {
					if (err.status === 503) {
						this.translocoService.selectTranslateObject('catalog').subscribe(t => {
							window.Telegram.WebApp.showAlert(t.shop_not_working, () => {
								window.Telegram.WebApp.close();
							});
						});
					}
					console.log(err);
				},
			});

		this.translationService.setTranslation();
	}

	private getShopInfo(shopId: string) {
		this.shopService.getShopInfo(shopId).subscribe({
			next: res => {
				this.shopService.subscriptionFeatures.next(res.subscriptionFeatures!);
				this.translocoService.setActiveLang(currencyLangBinding[res.currencyType!.currencyType || 'RUB']);
				this.shopService.setMoneySign(res.currencyType!);
				this.shopService.shopProductType.next(res.productType!.productType!);
				this.cartService.isWithoutPayment = res.paymentInfo!.withoutPayment!;
				this.cartService.paymentAvailable = !!res?.paymentInfo!.paymentTypes?.length && !res?.paymentInfo!.paymentTypes?.find(i => i === "NONE");
				if (
					this.router.url.includes('order-fallback') ||
					this.router.url.includes('order-success') ||
					this.router.url.includes('/order/')
				) {
					return;
				}
				this.router.navigate(['products'], { relativeTo: this.route });
			},
		});
	}
}
