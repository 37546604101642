import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'formatReceiver',
})
export class FormatReceiverPipe implements PipeTransform {
	transform(receiver?: any): string {
		if (!receiver) {
			return '';
		}
		return [
			receiver.name ? `${receiver.name}` : null,
			receiver.surname ? `${receiver.surname}` : null,
			receiver.phone ? `тел. ${receiver.phone}` : null,
		]
			.filter(Boolean)
			.join(', ');
	}
}
