import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { map, Observable, of } from 'rxjs';

@Pipe({
	name: 'getFile',
})
export class GetFilePipe implements PipeTransform {
	constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}

	transform(id: string | undefined, callback?: any): Observable<any> {
		if (!id) {
			return of('');
		}

		const params: any = {};

		return this.http
			.get(`https://cdn-dev.tgshop.io/api/v1/files/${id}`, { responseType: 'blob', params })
			.pipe(map(value => {if (typeof callback === 'function') {callback(this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(value)));} return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(value))}));
	}
}
