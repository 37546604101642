<div class="header">
	<h1 class="title">Заказ # {{ (orderData | async).orderNumber }}</h1>
</div>

<div class="product__list">
	<div class="product" *ngFor="let item of (orderData | async).items">
		<div class="wrap">
			<ng-container *ngIf="item.file">
				<img [src]="item.file?.id | getFile: 640 | async" [alt]="item.file?.filename" />
			</ng-container>
			<ng-container *ngIf="!item.file">
				<img src="/assets/default-img.jpg" alt="Нет фото" />
			</ng-container>

			<div class="product__col">
				<div class="product__name">
					{{ item.productName }}
				</div>

				<div class="product__desc" *ngIf="!shopService.isShopDigital()">
					<ng-container *ngIf="item.color?.colorName !== 'По умолчанию'">
						Цвет {{ item.color?.colorName }}
					</ng-container>
					<ng-container
						*ngIf="item.color?.colorName !== 'По умолчанию' && item.size?.sizeValue !== 'Без размера'"
					>
						{{ ', ' }}
					</ng-container>
					<ng-container *ngIf="item.size?.sizeValue !== 'Без размера'">
						Размер {{ item.size?.sizeValue }}
					</ng-container>
				</div>
			</div>
		</div>

		<div class="cost-wrap">
			<span class="product__realprice">
				{{ (item.realPrice ?? 0).toLocaleString() }}
				{{ shopService.shopMoneySign.value }}</span
			>
			<span
				*ngIf="item.productInfo?.price && item.productInfo?.price !== item.productInfo?.realPrice"
				class="product__price"
			>
				{{ (item.productInfo?.price ?? 0).toLocaleString() }} {{ shopService.shopMoneySign.value }}
			</span>
		</div>
	</div>
</div>

<div class="cart-info">
	<div class="cart-info__item">
		<div>Общая стоимость:</div>
		<div>{{ (orderData | async)!.price! }} {{ shopService.shopMoneySign.value }}</div>
	</div>

	<div *ngIf="(orderData | async)!.price! !== (orderData | async)!.realPrice!" class="cart-info__item">
		<div>Скидка:</div>
		<div>
			{{ '-' }}{{ ((orderData | async)!.price! - (orderData | async)!.realPrice!).toFixed(2) }}
			{{ shopService.shopMoneySign.value }}
		</div>
	</div>

	<div class="cart-info__item" *ngIf="(orderData | async).deliveryCost">
		<div>Доставка:</div>
		<div>{{ (orderData | async).deliveryCost }} {{ shopService.shopMoneySign.value }}</div>
	</div>
	<div class="cart-info__item" *ngIf="(orderData | async).deliveryCost === 0">
		<div>Доставка:</div>
		<div>Бесплатно</div>
	</div>

	<div class="cart-info__item">
		<div>Итого к оплате:</div>
		<div>
			{{ ((orderData | async)!.realPrice! + (orderData | async).deliveryCost).toFixed(2) }} {{ shopService.shopMoneySign.value }}
		</div>
	</div>
</div>

<tk-main-button text="Оплатить" [click]="goToPayment"></tk-main-button>
