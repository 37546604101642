import { hexToRgb } from '@utils/hex-to-rgb';
import { ThemeParams } from '@models/telegram/theme-params';

const MAP_THEME_PARAMS: Record<keyof ThemeParams, string> = {
	bg_color: '--tg-theme-bg-color',
	secondary_bg_color: '--tg-theme-secondary-bg-color',
	text_color: '--tg-theme-text-color',
	hint_color: '--tg-theme-hint-color',
	link_color: '--tg-theme-link-color',
	button_color: '--tg-theme-button-color',
	button_text_color: '--tg-theme-button-text-color',
};

const ALPHA = [4, 6, 8, 15, 20];

export const generateCssVariables = (themeParams: ThemeParams) => {
	const root = document.documentElement;
	Object.keys(themeParams).forEach(key => {
		ALPHA.forEach(alpha => {
			const rgb = hexToRgb(themeParams[key as keyof ThemeParams]);
			root.style.setProperty(
				`${MAP_THEME_PARAMS[key as keyof ThemeParams]}-${alpha}`,
				`rgba(${rgb}, ${alpha / 100})`,
			);
		});
	});
};
