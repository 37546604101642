import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CartService } from '@services/cart.service';
import { ActivatedRoute } from '@angular/router';
import { DeliveryType } from '@app/models/delivery-type.enum';

@Component({
	selector: 'tk-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckoutComponent {
	paymentLink: string | null = null;
	cart$ = this.cartService.productsInCart$;
	form = this.cartService.form;
	constructor(public cartService: CartService, private route: ActivatedRoute) {}

	shopId = this.route.parent?.snapshot.params['shopId'];
	DeliveryType = DeliveryType;
}
