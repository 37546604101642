import { Pipe, PipeTransform } from '@angular/core';

const DEFAULT_CURRENCY_LOCALE = 'ru-RU';
const DEFAULT_CURRENCY_OPTIONS = {
	style: 'currency',
	currency: 'RUB',
	minimumFractionDigits: 0,
};

@Pipe({
	name: 'formatCurrency',
})
export class FormatCurrencyPipe implements PipeTransform {
	transform(value: number, locale = DEFAULT_CURRENCY_LOCALE, options = DEFAULT_CURRENCY_OPTIONS): string {
		return new Intl.NumberFormat(locale, options).format(value);
	}
}
