/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerResponse } from './CustomerResponse';
import type { OrderItemResponse } from './OrderItemResponse';
import type { PromocodeResponse } from './PromocodeResponse';

/**
 * Полная информация о заказе
 */
export type OrderResponse = {
    /**
     * Идентификатор заказа
     */
    orderId?: string;
    /**
     * Номер заказа
     */
    orderNumber?: string;
    /**
     * Дата  заказа
     */
    orderDate?: string;
    /**
     * Сумма заказа
     */
    cost?: number;
    /**
     * Сумма заказа до вычета скидок
     */
    price?: number;
    /**
     * Размер скидки
     */
    discount?: number;
    /**
     * ФИО покупателя
     */
    receiverName?: string;
    /**
     * Статус оплаты
     */
    paymentState?: OrderResponse.paymentState;
    /**
     * Статус оплаты
     */
    paymentStateDescription?: string;
    /**
     * Статус заказа
     */
    orderProcessingState?: OrderResponse.orderProcessingState;
    /**
     * Статус заказа
     */
    orderProcessingStateDescription?: string;
    /**
     * Способ доставки
     */
    deliveryType?: OrderResponse.deliveryType;
    /**
     * Способ доставки
     */
    deliveryTypeDescription?: string;
    /**
     * Флаг для обозначения новых заказов
     */
    isViewed?: boolean;
    promocode?: PromocodeResponse;
    /**
     * Товары в заказе
     */
    items?: Array<OrderItemResponse>;
    customerResponse?: CustomerResponse;
    /**
     * Адрес доставки
     */
    address?: string;
    /**
     * Стоимость доставки
     */
    deliveryCost?: number;
    /**
     * Код службы доставки
     */
    providerKey?: string;
    /**
     * Наименование службы доставки
     */
    providerName?: string;
    /**
     * Тип доставки через apiship (до ПВЗ/курьером) код
     */
    apishipDeliveryType?: OrderResponse.apishipDeliveryType;
    /**
     * Тип доставки через apiship (до ПВЗ/курьером) значение
     */
    apishipDeliveryTypeDescription?: string;
    /**
     * Комментарий к заказу
     */
    comment?: string;
};

export namespace OrderResponse {

    /**
     * Статус оплаты
     */
    export enum paymentState {
        NOT_PAID = 'NOT_PAID',
        PAID = 'PAID',
        FAILED = 'FAILED',
        ISSUED = 'ISSUED',
    }

    /**
     * Статус заказа
     */
    export enum orderProcessingState {
        CREATED = 'CREATED',
        SENT = 'SENT',
        DELIVERED = 'DELIVERED',
        CANCELED = 'CANCELED',
    }

    /**
     * Способ доставки
     */
    export enum deliveryType {
        PICK_UP = 'PICK_UP',
        DELIVERY = 'DELIVERY',
        APISHIP = 'APISHIP',
        NONE = 'NONE',
    }

    /**
     * Тип доставки через apiship (до ПВЗ/курьером) код
     */
    export enum apishipDeliveryType {
        DELIVERY_TO_DOOR = 'DELIVERY_TO_DOOR',
        DELIVERY_TO_PVZ = 'DELIVERY_TO_PVZ',
    }


}

