import { Pipe, PipeTransform } from '@angular/core';
import { PositionResponse, ProductItemResponse } from '@models/generated/api';

@Pipe({
	name: 'productCartCount',
})
export class ProductCartCountPipe implements PipeTransform {
	transform(cartItems?: Array<ProductItemResponse>, currentPosition?: PositionResponse | null): number {
		return cartItems?.find(item => item.productInfo?.id === currentPosition?.id)?.amount ?? 0;
	}
}
