import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root',
})
export class MainButtonService {
	constructor(private location: Location) {}

	// TODO: Переписать в декларативном стиле
	showBackButton(callback: any) {
		window.Telegram.WebApp.BackButton.show();
		window.Telegram.WebApp.BackButton.onClick(() => this.location.back());
	}

	hideBackButton(callback: any) {
		window.Telegram.WebApp.BackButton.show();
		window.Telegram.WebApp.BackButton.offClick(callback);
	}
}
