<ng-container *transloco="let t">
	<div class="header">
		<h1 class="title">{{ t('order.cart') }}</h1>
		<button class="reset" (click)="resetCart()">{{ t('order.clear') }}</button>
	</div>

	<ng-container *tkLet="cart$ | async as cart">
		<div class="product__list">
			<div class="product" *ngFor="let item of cart?.items; trackBy: trackByFn">
				<div class="wrap">
					<ng-container *ngIf="item.productInfo?.photo">
						<img
							[src]="item.productInfo?.photo?.file?.id | getFile: 640 | async"
							[alt]="item.productInfo?.photo?.file?.filename"
						/>
					</ng-container>
					<ng-container *ngIf="!item.productInfo?.photo">
						<img src="/assets/default-img.jpg" alt="Нет фото" />
					</ng-container>

					<div class="product__col">
						<div class="product__name">
							{{ item.productInfo?.productName }}
						</div>

						<div class="product__desc" *ngIf="!shopService.isShopDigital()">
							<ng-container *ngIf="item.productInfo?.color?.colorName !== 'По умолчанию'">
								{{ t('catalog.color') }} {{ item.productInfo?.color?.colorName }}
							</ng-container>
							<ng-container
								*ngIf="
									item.productInfo?.color?.colorName !== 'По умолчанию' &&
									item.productInfo?.size?.sizeValue !== 'Без размера'
								"
							>
								{{ ', ' }}
							</ng-container>
							<ng-container *ngIf="item.productInfo?.size?.sizeValue !== 'Без размера'">
								{{ t('catalog.size') }} {{ item.productInfo?.size?.sizeValue }}
							</ng-container>
						</div>

						<tk-counter
							[count]="item.amount ?? 0"
							(increment)="changeAmountCart(item)"
							(decrement)="removeFromCart(item)"
						></tk-counter>
					</div>
				</div>

				<div class="cost-wrap">
					<span class="product__realprice">
						{{ (item.productInfo?.realPrice ?? 0).toLocaleString() }}
						{{ shopService.shopMoneySign.value }}</span
					>
					<span
						*ngIf="item.productInfo?.price && item.productInfo?.price !== item.productInfo?.realPrice"
						class="product__price"
					>
						{{ (item.productInfo?.price ?? 0).toLocaleString() }} {{ shopService.shopMoneySign.value }}
					</span>
				</div>
			</div>
		</div>

		<div class="total">
			<span>{{ t('order.total') }}:</span>
			<div class="wrap">
				<span *ngIf="cart?.price && cart?.price !== cart?.realPrice" class="total__price">
					{{ (cart?.price ?? 0).toLocaleString() }} {{ shopService.shopMoneySign.value }}
				</span>
				<span class="total__realprice">
					{{ (cart?.realPrice ?? 0).toLocaleString() }} {{ shopService.shopMoneySign.value }}
				</span>
			</div>
		</div>
	</ng-container>

	<ng-container *ngIf="(cartService.productsInCart$ | async)?.items?.length">
		<tk-main-button
			[text]="cartService.isWithoutPayment ? 'Перейти к оформлению' : t('order.checkout')"
			[click]="goToCheckout"
		></tk-main-button>
	</ng-container>
</ng-container>
