<ng-container *tkLet="cartService.productsInCart$ | async as cart">
	<h2>Товары</h2>

	<div class="items">
		<div *ngFor="let item of cart?.items" class="item">
			<img
				[src]="item.productInfo?.photo?.file?.id | getFile: 640 | async"
				[alt]="item.productInfo?.productName"
				class="item__image"
			/>
			<div class="item__content">
				<div class="item__name">{{ item.productInfo?.productName }}</div>
				<div class="item__amount">{{ item.amount }}&nbsp;шт.</div>
			</div>
			<div class="item__price">{{ (item.productInfo?.price ?? 0) * (item.amount ?? 0) | formatCurrency }}</div>
		</div>
	</div>
	<div class="total">
		<div class="total__title">Итого:</div>
		<div class="total__price">{{ cart?.realPrice ?? 0 | formatCurrency }}</div>
	</div>
	<h2>Оплата и доставка</h2>
	<a class="nav-button" routerLink="../delivery" fragment="type">
		<span class="nav-button__title">Способ доставки</span>
		<!--		<span class="nav-button__value">{{ form.value.deliveryOption | formatDeliveryType }}</span>-->
		<tk-icon size="16" icon="chevron-right"></tk-icon>
	</a>
	<a class="nav-button" routerLink="../delivery" fragment="delivery">
		<span class="nav-button__title">Информация о доставке</span>

		<tk-icon size="16" icon="chevron-right"></tk-icon>
	</a>
	<a class="nav-button" routerLink="../delivery" fragment="receiver">
		<span class="nav-button__title">Получатель</span>
		<tk-icon size="16" icon="chevron-right"></tk-icon>
	</a>

	<h2>Комментарии к заказу</h2>
	<tk-form-field>
		<input [formControl]="$any(form.get('comment'))" placeholder="Напишите что-нибудь" />
	</tk-form-field>

	<ng-container *ngIf="cart?.items?.length">
		<tk-main-button text="Оплатить {{ (cart$ | async)?.realPrice ?? 0 | formatCurrency }}"></tk-main-button
	></ng-container>
</ng-container>
