/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Apiship Служба доставки сгруппированные по коду
 */
export type ApishipDeliveryServicesGroupedByKeyResponse = {
    /**
     * Код службы доставки
     */
    providerKey?: string;
    /**
     * Цена минимального тарифа
     */
    minimalDeliveryCost?: number;
    /**
     * Идентификатор компании
     */
    companyId?: string;
    /**
     * Флаг активности СД
     */
    isEnabled?: boolean;
    /**
     * Тип забора груза
     */
    productTransferType?: ApishipDeliveryServicesGroupedByKeyResponse.productTransferType;
    /**
     * Идентификатор пункта приема товара
     */
    pickPointId?: number;
    /**
     * Адрес пункта приема товара
     */
    pickPointAddress?: string;
};

export namespace ApishipDeliveryServicesGroupedByKeyResponse {

    /**
     * Тип забора груза
     */
    export enum productTransferType {
        FROM_WAREHOUSE = 'FROM_WAREHOUSE',
        FROM_DELIVERY_SERVICE_WAREHOUSE = 'FROM_DELIVERY_SERVICE_WAREHOUSE',
    }


}

