<ng-container *transloco="let t">
	<img class="icon" src="/assets/order.png" alt="Order" />

	<h1 class="title">
		{{ t('order.success_text.success_text_1') }}
		<div>№{{ route.snapshot.queryParams['order'] || route.snapshot.queryParams['orderNum'] }}</div>
		{{ t('order.success_text.success_text_2') }}
	</h1>
	<div class="desc">
		{{ t('order.success_text.success_text_3') }}
	</div>
	<div class="desc">
		{{ t('order.success_text.success_text_4') }}
	</div>

	<ng-container>
		<tk-main-button [text]="t('order.back')" [click]="goToProducts"></tk-main-button>
	</ng-container>
</ng-container>
