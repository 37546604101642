import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '@services/products.service';
import { CartService } from '@services/cart.service';
import { MainButtonService } from '@services/main-button.service';
import { DestroyService } from '@services/destroy.service';
import { takeUntil } from 'rxjs';
import { LoadingService } from '@services/loading.service';
import { PaginationModel } from '@models/pagination.model';
import { AuthService } from '@services/auth.service';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'tk-products',
	templateUrl: './products.component.html',
	styleUrls: ['./products.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
})
export class ProductsComponent extends PaginationModel implements AfterViewChecked {
	products$ = this.productsService.products$;
	shopId;
	isReturned: boolean = false;

	activeCategory = this.productsService.activeCategory;
	activeSort = this.productsService.activeSort;

	searchControl = new FormControl();
	search: boolean = false;

	constructor(
		private route: ActivatedRoute,
		public productsService: ProductsService,
		public cartService: CartService,
		private cdr: ChangeDetectorRef,
		private router: Router,
		private mainButton: MainButtonService,
		private ngZone: NgZone,
		private destroy$: DestroyService,
		public loading$: LoadingService,
		private authService: AuthService,
	) {
		super();
		window.Telegram.WebApp.MainButton.enable();
		this.authService.openProductPage = true;
		this.loading$.next(true);
		this.shopId = this.route.parent?.snapshot.params['shopId'];

		if (!authService.token) {
			this.router.navigate([`shops`, this.shopId]);
			return;
		}

		this.cartService.getBasket(this.shopId).subscribe(cart => {
			this.cartService.productsInCart$.next(cart);
		});

		if (this.productsService.searchStr.value !== null) {
			this.searchControl.setValue(this.productsService.searchStr.value);
		}

		this.cartService.refreshCart(this.shopId);

		if (!this.productsService.categories.value) {
			this.productsService.getCategories(this.shopId).subscribe({
				next: res => {
					this.productsService.categories.next(res);
				},
			});
		}

		if (!this.productsService.sortings.value) {
			this.productsService.getSort(this.shopId).subscribe({
				next: res => {
					this.productsService.sortings.next(res);
				},
			});
		}
	}

	ngAfterViewChecked() {
		if (this.isReturned) {
			return;
		}
		if (this.productsService.newCategory.value || this.productsService.newSort.value) {
			this.refresh();
			this.productsService.newCategory.next(false);
			this.productsService.newSort.next(false);
		} else {
			this.loading$.next(false);
		}
		if (!this.route.snapshot.queryParams['newCategory'] && this.products$.value.totalPages) {
			if (this.productsService.currProductId.value !== '') {
				const el = document.getElementById(this.productsService.currProductId.value);
				el?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' });
			}
			if (this.productsService.currProductPage.value > 0) {
				this.changePage(this.productsService.currProductPage.value);
			}
			this.loading$.next(false);
		} else {
			this.refresh();
		}
		this.isReturned = true;
	}

	private refresh(): void {
		this.loading$.next(true);
		this.changePage(0);
		this.productsService
			.getProducts(
				this.shopId,
				this.currentSettings,
				this.activeCategory,
				this.searchControl.value,
				this.activeSort.value!,
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (response: any) => {
					this.products$.next(response);
					this.loading$.next(false);
					this.cdr.markForCheck();
				},
			});
	}

	onChangeSearch() {
		this.productsService.searchStr.next(this.searchControl.value);
		if (this.searchControl.value?.length < 3) {
			if (this.search) {
				this.search = false;
				this.refresh();
			}
			return;
		} else {
			this.search = true;
		}
		if (this.search) {
			this.refresh();
		}
	}

	onScroll() {
		if (this.currentSettings.page! + 1 >= this.products$.value.totalPages!) {
			return;
		}
		this.loading$.next(true);
		this.changePage(this.currentSettings.page! + 1);
		this.productsService
			.getProducts(
				this.shopId,
				this.currentSettings,
				this.activeCategory,
				this.searchControl.value,
				this.activeSort.value!,
			)
			.subscribe({
				next: (response: any) => {
					const data = {
						...response,
						content: [
							// @ts-ignore
							...this.products$.value.content,
							...response.content,
						],
					};
					this.products$.next(data);
					this.loading$.next(false);
					this.productsService.currProductPage.next(this.currentSettings.page!);
				},
			});
	}

	openCategories() {
		this.router.navigate([`shops`, this.shopId, 'categories']);
		// this.router.navigate([`shops`, this.shopId, 'cart']);
	}

	openSort() {
		this.router.navigate([`shops`, this.shopId, 'sort']);
	}

	goToCart = () => {
		console.log("go to cart")
		this.router.navigate([`shops`, this.shopId, 'cart']);
	};
}
