<ng-container *transloco="let t">
	<div class="search">
		<tk-form-field class="search__input">
			<input
				type="text"
				tkInput
				[formControl]="searchControl"
				(ngModelChange)="onChangeSearch()"
				placeholder="Я ищу..."
			/>

			<tk-icon tkSuffix icon="search" size="14"></tk-icon>
		</tk-form-field>
		<div class="sort-btn" (click)="openSort()">
			<tk-icon size="24" icon="filter"></tk-icon>
		</div>
	</div>

	<ng-container *ngIf="productsService.categories.value?.categories?.length">
		<div class="cat" (click)="openCategories()">
			<div class="flex">
				<div class="cat__title">{{ t('catalog.categories') }}</div>
				<div class="cat__name">
					{{
						productsService.activeCategory.value
							? productsService.activeCategory.value.categoryName
							: t('catalog.all_products')
					}}
				</div>
			</div>
			<tk-icon size="16" icon="chevron-right"></tk-icon>
		</div>
	</ng-container>

	<ng-container *ngIf="(products$ | async)?.content?.length === 0 && !loading$.value">
		Не найдено ни одного товара
	</ng-container>
	<div
		*ngIf="searchControl.value?.length > 2 && (products$ | async)!.content?.length && !loading$.value"
		class="search-text"
	>
		В категории
		<span>
			{{
				productsService.activeCategory.value
					? productsService.activeCategory.value.categoryName
					: t('catalog.all_products')
			}}
		</span>
		найдено {{ products$.value.totalElements }} товаров
	</div>
	<div
		class="products"
		infiniteScroll
		[infiniteScrollDistance]="2"
		[infiniteScrollThrottle]="50"
		(scrolled)="onScroll()"
	>
		<tk-product-card
			class="card"
			*ngFor="let product of (products$ | async)!.content"
			[product]="product"
			[id]="product.productId"
		></tk-product-card>

		<ng-container *ngIf="loading$ | async">
			<div class="loading">
				<div class="skeleton" *ngFor="let _ of [].constructor(4)">
					<div class="wrap">
						<tk-skeleton class="photo"></tk-skeleton>
						<div class="product__content">
							<tk-skeleton class="price"></tk-skeleton>
							<tk-skeleton class="name"></tk-skeleton>
						</div>
						<tk-skeleton class="button"></tk-skeleton>
					</div>
				</div>
			</div>
		</ng-container>
	</div>

	<ng-container *ngIf="(cartService.productsInCart$ | async)?.items?.length">
		<tk-main-button [text]="t('order.cart')" [click]='goToCart'></tk-main-button>
	</ng-container>
</ng-container>
