import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { CartService } from '@services/cart.service';
import { ProductItemResponse } from '@models/generated/api';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyService } from '@services/destroy.service';
import { MainButtonService } from '@services/main-button.service';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { ShopService } from '@services/shop.service';

@Component({
	selector: 'tk-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.sass'],
	providers: [DestroyService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartComponent implements OnDestroy {
	paymentLink: string | null = null;
	cart$ = this.cartService.productsInCart$;

	minSum = new BehaviorSubject<number | undefined>(0);
	prevPage: any = null;

	constructor(
		public cartService: CartService,
		private route: ActivatedRoute,
		private router: Router,
		private mainButton: MainButtonService,
		private destroy$: DestroyService,
		private cdr: ChangeDetectorRef,
		private translocoService: TranslocoService,
		public shopService: ShopService,
		private location: Location,
	) {
		this.refresh();

		this.cart$.subscribe({
			next: () => {
				if (!this.router.url.includes('/cart')) {
					return;
				}

				this.cartService.getBasketSettings(this.shopId).subscribe({
					next: res => {
						this.translocoService.selectTranslateObject('order').subscribe(t => {
							if (this.cart$.value.realPrice! >= res.minimumOrderSum!) {
								window.Telegram.WebApp.MainButton.setText(
									cartService.isWithoutPayment ? 'Перейти к оформлению' : t['checkout'],
								);
								window.Telegram.WebApp.MainButton.enable();
							} else {
								window.Telegram.WebApp.MainButton.setText(
									`${t['order_sum_1']} ${res.minimumOrderSum!.toLocaleString()} ${
										this.shopService.shopMoneySign.value
									} ${t['order_sum_2']}. ${t['order_sum_3']} ${(
										res.minimumOrderSum! - this.cart$.value.realPrice!
									)
										.toFixed(2)!
										.toLocaleString()} ${this.shopService.shopMoneySign.value} ${
										t['order_sum_4']
									}.`,
								);
								window.Telegram.WebApp.MainButton.disable();
							}
							this.cdr.markForCheck();
						});

						this.cdr.markForCheck();
					},
					error: err => {
						console.log(err);
					},
				});
			},
		});

		const BackButton = window.Telegram.WebApp.BackButton;
		BackButton.show();
	}

	get shopId() {
		return this.route.parent?.snapshot.params['shopId'];
	}

	refresh() {
		const shopId = this.route?.parent?.snapshot.params?.['shopId'];

		// this.cartService.checkPaymentsExists(shopId).subscribe({
		// 	next: res => {
		// 		this.cartService.isWithoutPayment = res.withoutPayment!;
		// 		this.cartService.paymentAvailable = res?.paymentAvailable ?? true;
		// 	},
		// });
		this.cartService.refreshCart(shopId);
	}

	trackByFn(item: any) {
		return item.position?.positionId;
	}

	changeAmountCart(product: ProductItemResponse): void {
		this.cartService.changeAmountCart(1, this.shopId, product?.productInfo?.id, this.shopService.isShopDigital());
	}

	removeFromCart(product: ProductItemResponse): void {
		this.cartService.changeAmountCart(-1, this.shopId, product?.productInfo?.id, this.shopService.isShopDigital());
	}

	resetCart(): void {
		this.cartService
			.resetBasket(this.shopId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.cartService.refreshCart(this.shopId);
			});
	}

	goBack = (): void => {
		if (this.prevPage) {
			this.router.navigate([this.prevPage]);
		} else {
			this.location.back();
		}
	};

	goToCheckout = (): void => {
		if (!this.cartService.paymentAvailable) {
			this.translocoService.selectTranslateObject('order').subscribe(t => {
				window.Telegram.WebApp.showAlert(`${t.payment_unavailable}`);
				return;
			});
		} else {
			this.router.navigate([`shops`, this.shopId, 'delivery']);
		}
	};

	ngOnDestroy() {
		window.Telegram.WebApp.MainButton.enable();
	}
}
