import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DestroyService } from '@services/destroy.service';
import { LoadingService } from '@services/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '@services/products.service';
import { CategoryResponse } from '@models/generated/api';

@Component({
	selector: 'tk-categories',
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
})
export class CategoriesComponent {
	categories: any = this.productsService.categories;
	activeCategory: any = this.productsService.activeCategory.value;
	shopId;

	constructor(private route: ActivatedRoute, public productsService: ProductsService, private router: Router) {
		this.shopId = this.route.parent?.snapshot.params['shopId'];

		if (this.categories.value?.categories?.length > 0) {
			this.productsService.categories.next({
				...this.productsService.categories.value,
				categories: this.addDepth(this.categories.value.categories),
			});
		}

		if (!this.categories.value) {
			this.toProducts();
		}

		const BackButton = window.Telegram.WebApp.BackButton;
		BackButton.show();
	}

	addDepth(arr: Array<any>, depth = 0): Array<any> {
		arr.forEach(obj => {
			obj.depth = depth;
			this.addDepth(obj.children, depth + 1);
		});

		return arr;
	}

	select(cat: CategoryResponse | null) {
		this.activeCategory = cat;
	}

	choose = (): void => {
		this.productsService.activeCategory.next(this.activeCategory);
		this.productsService.newCategory.next(true);
		this.productsService.currProductId.next('');
		this.productsService.currProductPage.next(0);
		this.toProducts();
	};

	toProducts() {
		this.router.navigate([`shops`, this.shopId, 'products']);
	}

	shouldShowCategory(cat: any): boolean {
		const el = document.getElementById(cat.categoryId);
		let result: boolean = cat.categoryId === this.activeCategory?.categoryId;
		if (cat.depth === 0 || el?.parentElement?.className.split(' ').indexOf('active')! >= 0) {
			return true;
		}
		for (let i of cat.children) {
			if (this.shouldShowCategory(i)) {
				result = true;
			}
		}

		return result;
	}
}
