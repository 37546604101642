/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Форма редактирования кастомного способа оплаты
 */
export type PaymentCustomDtoRequest = {
    name?: string;
    description?: string;
    discountValue?: number;
    discountType?: PaymentCustomDtoRequest.discountType;
    vatCode?: PaymentCustomDtoRequest.vatCode;
};

export namespace PaymentCustomDtoRequest {

    export enum discountType {
        DISCOUNT = 'DISCOUNT',
        PERCENT = 'PERCENT',
    }

    export enum vatCode {
        NO_VAT = 'NO_VAT',
        VAT_0 = 'VAT_0',
        VAT_10 = 'VAT_10',
        VAT_20 = 'VAT_20',
        VAT_10_110 = 'VAT_10_110',
        VAT_20_120 = 'VAT_20_120',
    }


}

