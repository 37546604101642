import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DestroyService } from '@services/destroy.service';
import { LoadingService } from '@services/loading.service';
import { CartService } from '@services/cart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';

@Component({
	selector: 'tk-pickup-points',
	templateUrl: './pickup-points.component.html',
	styleUrls: ['./pickup-points.component.sass'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [DestroyService, LoadingService],
})
export class PickupPointsComponent {
	shopId;
	list = new BehaviorSubject<any>(null);
	searchControl = new FormControl();

	constructor(
		public cartService: CartService,
		private router: Router,
		private route: ActivatedRoute,
		public loading$: LoadingService,
		private location: Location,
	) {
		this.shopId = this.route.parent?.snapshot.params['shopId'];

		this.searchControl.valueChanges.subscribe({
			next: res => {
				this.cartService.getCityList(res).subscribe({
					next: data => {
						this.list.next(data);
					},
				});
			},
		});
	}

	setCity = (city: string | null) => {
		this.cartService.selectedCity = city;
		this.cartService.pickupSelectedPoint = null;
		this.cartService.apishipSelectedPoint = null;
		this.cartService.form.patchValue({ deliveryOption: null });
		this.loading$.next(true);
		this.location.back();
	};
}
