/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Форма добавления поля оформления заказа
 */
export type ShopOrderFieldRequest = {
    /**
     * Тип поля оформления заказа
     */
    shopOrderFieldType: ShopOrderFieldRequest.shopOrderFieldType;
    /**
     * Признак обязательности поля
     */
    required: boolean;
    /**
     * Минимальная длина
     */
    minLength: number;
    /**
     * Максимальная длина
     */
    maxLength: number;
    /**
     * Признак активности поля
     */
    enabled: boolean;
};

export namespace ShopOrderFieldRequest {

    /**
     * Тип поля оформления заказа
     */
    export enum shopOrderFieldType {
        FIRST_NAME = 'FIRST_NAME',
        LAST_NAME = 'LAST_NAME',
        PATRONYMIC = 'PATRONYMIC',
        PHONE = 'PHONE',
        EMAIL = 'EMAIL',
        COMMENT = 'COMMENT',
        PROMOCODE = 'PROMOCODE',
        CUSTOM = 'CUSTOM',
    }


}

