/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Магазин
 */
export type ShopResponse = {
    /**
     * Идентификатор
     */
    id?: string;
    /**
     * Название магазина
     */
    shopName?: string;
    /**
     * Состояние магазина
     */
    shopState?: ShopResponse.shopState;
    /**
     * Состояние магазина
     */
    shopStateDescription?: string;
    /**
     * Токен телеграм бота
     */
    botToken?: string;
    /**
     * Логин автора магазина
     */
    authorTelegramUserName?: string;
    /**
     * Имя бота в телеграм
     */
    botName?: string;
    /**
     * Логин бота в телеграм
     */
    botUserName?: string;
    /**
     * Дата создания магазина
     */
    createdAt?: string;
    /**
     * Минимальная сумма заказа в магазине
     */
    minimumOrderSum?: number;
    /**
     * Валюта магазина
     */
    currency?: ShopResponse.currency;
    /**
     * Тип продуктов в магазине
     */
    productType?: ShopResponse.productType;
    /**
     * Тип продуктов в магазине
     */
    productTypeDescription?: string;
};

export namespace ShopResponse {

    /**
     * Состояние магазина
     */
    export enum shopState {
        ACTIVE = 'ACTIVE',
        STOPPING = 'STOPPING',
        DISABLE = 'DISABLE',
    }

    /**
     * Валюта магазина
     */
    export enum currency {
        RUB = 'RUB',
        UZS = 'UZS',
        USD = 'USD',
    }

    /**
     * Тип продуктов в магазине
     */
    export enum productType {
        GOODS = 'GOODS',
        DIGITAL = 'DIGITAL',
    }


}

