import { Component, NgZone } from '@angular/core';
import { generateCssVariables } from '@utils/generate-css-variables';
import { AuthService } from '@services/auth.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ShopService } from '@services/shop.service';
import { Location } from '@angular/common';

@Component({
	selector: 'tk-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.sass'],
})
export class AppComponent {
	showFooter: boolean = true;

	constructor(
		private authService: AuthService,
		private router: Router,
		private route: ActivatedRoute,
		private shopService: ShopService,
		private location: Location,
		private zone: NgZone,
	) {
		const BackButton = window.Telegram.WebApp.BackButton;
		this.authService.token = '';

		window.Telegram.WebApp.ready();
		window.Telegram.WebApp.enableClosingConfirmation();
		window.Telegram.WebApp.expand();

		generateCssVariables(window.Telegram.WebApp.themeParams);

		router.events.subscribe(val => {
			if (val instanceof NavigationEnd) {
				this.showFooter =
					!this.router.url.includes('/products/') &&
					!this.shopService.subscriptionFeatures.value?.find(feature => feature.code === 'NO_ADS')
						?.available!;
			}
		});

		BackButton.onClick(() => {
			BackButton.hide();
			if (this.authService.openProductPage) {
				this.location.back();
			} else {
				this.zone.run(() => {
					this.router.navigate([`shops`, this.shopService.shopId, 'products']);
				});
			}
		});
	}
}
